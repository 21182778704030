import React, { useEffect } from 'react';
import { func, string } from 'prop-types';
import { Trackings } from '../../constants';
import InlineLimitAlert from './InlineLimitAlert';
import AlertRenderer from './AlertRenderer';

const InlineLimitAlertWithTrackings = ({
  registerJotformAction,
  builderType,
  ...props
}) => {
  const utmCampaign = builderType === 'card' ? 'upgrade-from-account-box-editor_cf' : 'upgrade-from-account-box-editor';
  const utmContent = `?utm_source=jNewHeader&utm_medium=banner&utm_campaign=${utmCampaign}&utm_term=Upgrade%20for%20more&utm_content=inline_limit_alert_upgrade_button`;

  useEffect(() => {
    const { ENTERPRISE_ALERT_SEEN } = Trackings;
    registerJotformAction(ENTERPRISE_ALERT_SEEN);
  }, []);

  const handleUpgradeYourPlanClick = () => {
    const { INLINE_ALERT_UPGRADE_CLICK } = Trackings;
    registerJotformAction(INLINE_ALERT_UPGRADE_CLICK);
  };

  return (
    <InlineLimitAlert
      {...props}
      utmContent={utmContent}
      onCtaClick={handleUpgradeYourPlanClick}
      AlertRenderer={AlertRenderer}
    />
  );
};

InlineLimitAlertWithTrackings.propTypes = {
  builderType: string,
  registerJotformAction: func.isRequired
};

InlineLimitAlertWithTrackings.defaultProps = {
  builderType: ''
};

export default InlineLimitAlertWithTrackings;
