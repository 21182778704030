import React from 'react';
import { func, oneOf, string } from 'prop-types';
import { translationRenderer } from '@jotforminc/translation';
import { IconExclamationCircleFilled } from '@jotforminc/svg-icons';
import { INLINE_ALERT_TYPES } from '../../constants';

const AlertRenderer = ({ text, alertType, onCtaClick }) => {
  const getClassName = () => `jNewHeader-alert ${alertType === INLINE_ALERT_TYPES.DANGER ? 'danger' : 'warning'}`;

  return (
    <div
      role="alert"
      className={getClassName()}
    >
      <IconExclamationCircleFilled />
      <p>
        {translationRenderer(text)({
          renderer1: linkText => (
            <a
              key="buttonText"
              href={window.location.href}
              onClick={onCtaClick}
            >
              {linkText}
            </a>
          )
        })}
      </p>
    </div>
  );
};

AlertRenderer.propTypes = {
  text: string.isRequired,
  alertType: oneOf([
    INLINE_ALERT_TYPES.ENTERPRISE,
    INLINE_ALERT_TYPES.WARNING,
    INLINE_ALERT_TYPES.DANGER
  ]).isRequired,
  onCtaClick: func.isRequired
};

export default AlertRenderer;
