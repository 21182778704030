export const Trackings = {
  ACCOUNT_BOX_SEEN: { action: 'seen', target: 'accountBoxStandalone' },
  HEADER_CLICK: { action: 'click', target: 'headerSection' },
  INLINE_ALERT_SEEN: { action: 'seen', target: 'inlineLimitAlert' },
  INLINE_ALERT_UPGRADE_CLICK: { action: 'click', target: 'inlineLimitAlert_upgradeYourPlan' },
  ENTERPRISE_ALERT_SEEN: { action: 'seen', target: 'enterpriseInlineLimitAlert' },
  ENTERPRISE_ALERT_CS_CLICK: { action: 'click', target: 'enterpriseInlineLimitAlert_contactSalesButton' },
  ENTERPRISE_ALERT_LM_CLICK: { action: 'click', target: 'enterpriseInlineLimitAlert_learnMoreButton' },
  VIEW_ALL_CLICK: { action: 'click', target: 'viewAllButton' },
  UPGRADE_YOUR_PLAN_CLICK: { action: 'click', target: 'upgradeYourPlanButton' },
  ADMIN_CONSOLE_PROMO_CLICK: { action: 'click', target: 'adminConsolePromotionButton' },
  ADMIN_CONSOLE_URL_CLICK: { action: 'click', target: 'adminConsoleURLText' },
  SETTINGS_CLICK: { action: 'click', target: 'settingsButton' },
  SWITCH_TO_OLD_CLICK: { action: 'click', target: 'switchToOldButton' },
  LOGOUT_CLICK: { action: 'click', target: 'logoutButton' }
};
