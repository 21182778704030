import React, { useEffect, useState } from 'react';
import { node, string } from 'prop-types';

import {
  Dialog,
  DialogBody,
  DialogCloseButton
} from '@jotforminc/magnet';
import { readCookie, setCookie } from '@jotforminc/utils';

import './exit-modal.scss';

import { handleShowExitModal } from '../../../utils/exit';

import { useAssetInfo } from '../../../hooks/useCampaignInfo';

const ExitModal = ({
  children,
  title,
  onClose
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    type: campaignType,
    utm_campaign: utmCampaign,
    assetsVersion
  } = useAssetInfo();

  const isHideCookie = readCookie(`GAM:Exit:${utmCampaign}:${assetsVersion}:hide`) === 'true';
  const [isOpenCheck, setIsOpenCheck] = useState(!isHideCookie);

  const handleOnClose = () => {
    setIsOpen(false);
    setIsOpenCheck(false);
    onClose();
  };

  const handleShow = () => {
    if (isOpenCheck) {
      setCookie(`GAM:Exit:${utmCampaign}:${assetsVersion}:hide`, 'true', 1);
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.querySelector('body').classList.add('jf-exit-modal');
    document.addEventListener('mouseout', event => handleShowExitModal(event, handleShow));
    return () => {
      document.removeEventListener('mouseout', handleShowExitModal);
    };
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={handleOnClose}
      ariaLabel={title}
      id="exit-modal"
      data-campaign-type={campaignType}
    >
      <DialogBody>
        {children}
      </DialogBody>
      <DialogCloseButton onClick={handleOnClose} />
    </Dialog>
  );
};

ExitModal.propTypes = {
  children: node.isRequired,
  title: string.isRequired,
  onClose: () => {}
};

ExitModal.defaultProps = {
  onClose: () => {}
};

export default ExitModal;
