import React from 'react';
import capitalize from 'lodash/capitalize';
import cx from 'classnames';
import { t, translationRenderer } from '@jotforminc/translation';
import { useFormatCurrency } from '@jotforminc/currency';

import { usePricingContext } from '../context/pricingTableContext';

import '../styles/legacy_plan.scss';

import {
  LEGACY_PLAN_USER_INFORMATION,
  AS_JOTFORM_HAS_GROWN_AND_EVOLVED_OVER_TIME_ALTERNATIVE,
  HIPAA_COMPLIANCE_GRANDFATHERED,
  HIPAA_COMPLIANCE_SILVER,
  HIPAA_COMPLIANCE_GOLD_ENTERPRISE
} from '../constants/texts';
import {
  CAMPAIGN_TYPES, PLANS, SOURCES, THEMES
} from '../constants';

const NoticeBox = () => {
  const {
    state: {
      user,
      userPlan,
      userCurrentPeriod,
      lastPaymentAmount,
      hipaaGrandfathered,
      source,
      theme,
      campaign: { type: campaignType },
      campaignStatus
    }
  } = usePricingContext();
  const { account_type: { planType: priceType, currentPlanType: currentPriceType } = {} } = user;

  const formatCurrency = useFormatCurrency();

  const showGrandfatherUserNotification = (
    [PLANS.PREMIUM, PLANS.ECONOMY, PLANS.PROFESSIONAL].includes(userPlan)
    || (
      Object.keys(PLANS).filter(plan => ![PLANS.ADMIN, PLANS.FREE, PLANS.STORAGE, PLANS['Sub User']].includes(plan)).includes(userPlan)
      && (currentPriceType !== priceType) && (currentPriceType < priceType) && !(userPlan === PLANS.GOLD && priceType === '2024')
    )
  );

  const noticeBoxBgStyles = campaign => {
    if (!campaignStatus) {
      return {
        background: 'linear-gradient(0deg, rgba(46, 105, 255, 0.1), rgba(46, 105, 255, 0.1)), #FFFFFF',
        borderColor: '#2E69FF',
        color: '#57647E',
        maxWidth: '906px'
      };
    }

    switch (campaign) {
      case CAMPAIGN_TYPES.BLACKFRIDAY:
        return {
          backgroundColor: '#06141e',
          borderColor: '#454E80',
          color: '#8596B7'
        };
      case CAMPAIGN_TYPES.EOY:
        return {
          background: 'linear-gradient(90deg, #0075E3 0%, #0066C3 100%)',
          color: '#ffffff',
          border: 'none'
        };
      case CAMPAIGN_TYPES.SUMMER:
        return {
          borderColor: 'rgba(11, 21, 81, 0.2)',
          color: '#0b1551'
        };
      default:
        return {
          backgroundColor:
            'linear-gradient(0deg, rgba(46, 105, 255, 0.1), rgba(46, 105, 255, 0.1)), #FFFFFF',
          borderColor: '#2E69FF',
          color: '#57647E'
        };
    }
  };

  const noticeBoxTitleStyles = campaign => {
    if (!campaignStatus) {
      return {
        color: '#2E69FF'
      };
    }

    switch (campaign) {
      case CAMPAIGN_TYPES.BLACKFRIDAY:
        return {
          color: '#FF6037'
        };
      case CAMPAIGN_TYPES.EOY:
        return {
          color: '#fff'
        };
      case CAMPAIGN_TYPES.SUMMER:
        return {
          color: '#ff6038'
        };
      default:
        return {
          color: '#2E69FF'
        };
    }
  };

  const HIPAAClasses = cx('max-w-md mx-auto text-center text-xl color-gray-400 px-2 font-light', {
    'mt-32': [CAMPAIGN_TYPES.NEWUSER, CAMPAIGN_TYPES.OVERQUOTAUSER, CAMPAIGN_TYPES.BACKTOPAID].includes(campaignType),
    'lg:my-10': ![CAMPAIGN_TYPES.NEWUSER, CAMPAIGN_TYPES.OVERQUOTAUSER, CAMPAIGN_TYPES.BACKTOPAID].includes(campaignType)
  });

  if (source === SOURCES.HIPAA_PRICING) {
    return (
      <div className={HIPAAClasses}>
        {hipaaGrandfathered ? translationRenderer(HIPAA_COMPLIANCE_SILVER)({
          renderer1: text => <strong className="font-bold">{text}</strong>,
          renderer2: text => <strong className="font-bold">{text}</strong>
        }) : translationRenderer(HIPAA_COMPLIANCE_GOLD_ENTERPRISE)({
          renderer1: text => <strong className="font-bold">{text}</strong>
        })}
      </div>
    );
  }

  if (showGrandfatherUserNotification && [THEMES.DEFAULT, THEMES.BILLING].includes(theme)) {
    const prettyPeriod = userCurrentPeriod.slice(0, -2);
    return (
      <div id="legacy-plan-notice-box" className='max-w-md mx-auto flex justify-center py-5'>
        <div
          className='flex flex-col w-11/12 pt-4 pr-8 pb-6 pl-12 border radius-lg'
          style={noticeBoxBgStyles(campaignType)}
        >
          <h6 className="font-bold text-md line-height-xl mb-2 text-center" style={noticeBoxTitleStyles(campaignType)}>{t(LEGACY_PLAN_USER_INFORMATION)}</h6>
          <ul className="list-disc space-y-6 line-height-xl text-sm">
            <li dangerouslySetInnerHTML={{
              __html: t(
                AS_JOTFORM_HAS_GROWN_AND_EVOLVED_OVER_TIME_ALTERNATIVE
              )
                .replace('{plan}', capitalize(t(userPlan)))
                .replace(
                  '{price}',
                  formatCurrency({
                    value: typeof lastPaymentAmount === 'string' ? parseInt(lastPaymentAmount, 10) : lastPaymentAmount
                  })
                )
                .replace('{prettyPeriod}', prettyPeriod)
            }}
            />
            {hipaaGrandfathered && (
            <li
              dangerouslySetInnerHTML={{
                __html: t(HIPAA_COMPLIANCE_GRANDFATHERED)
              }}
            />
            )}
          </ul>
        </div>
      </div>
    );
  }

  if (hipaaGrandfathered && [THEMES.DEFAULT, THEMES.BILLING].includes(theme)) {
    return (
      <div className='max-w-md mx-auto flex justify-center py-5'>
        <div
          className='flex flex-col w-11/12 pt-4 pr-8 pb-6 pl-12 border radius-lg'
          style={{ background: 'linear-gradient(0deg, rgba(46, 105, 255, 0.1), rgba(46, 105, 255, 0.1)), #FFFFFF', borderColor: '#2E69FF', color: '#57647E' }}
        >
          <h6 className="font-bold text-md line-height-xl mb-2 text-center" style={{ color: '#2E69FF' }}>{t(LEGACY_PLAN_USER_INFORMATION)}</h6>
          <ul className="list-disc space-y-6 line-height-xl">
            <li
              dangerouslySetInnerHTML={{
                __html: t(HIPAA_COMPLIANCE_GRANDFATHERED)
              }}
            />
          </ul>
        </div>
      </div>
    );
  }

  return null;
};

export default NoticeBox;
