/* eslint-disable complexity */
// TODO: remove isFormUser prop
import {
  bool, func, oneOfType, shape, string
} from 'prop-types';
import React, { Component } from 'react';
import { ABTestManager, ActionManager } from '@jotforminc/abtest-manager';
import {
  LimitEstimationWarning, AccountBoxLimitWarnings
} from '@jotforminc/limit-assets';
import { Button } from '@jotforminc/magnet';
import {
  getUrlParameter, readCookie, calculateLimitWarning, shouldShowEstimationWarning
} from '@jotforminc/utils';
import { IconInfoCircleFilled, IconPrintXmark } from '@jotforminc/svg-icons';
import { t, translationRenderer } from '@jotforminc/translation';
import isEmpty from 'lodash/isEmpty';

import { findHighestLimit, isGold } from '../../utils';
import {
  DEFAULT_LANG, INLINE_LIMIT_ALERT_TRESHOLD, LIMIT_LABEL_MAP, PROJECT_NAME, Texts, Trackings
} from '../../constants';
import EnterpriseInlineLimitAlert from './EnterpriseInlineLimitAlert';
import InlineLimitAlertWithTrackings from './InlineLimitAlertWithTrackings';
import { InvoiceWarning } from './InvoiceWarning';
import MenuItems from './MenuItems';
import Limits from './Limits';
import Header from './Header';
import '../../styles/_jfAccountBox.scss';
import { getRecalculationRemainingTime } from '../../api';

const isErrorInvoice = invoice => {
  const currentDate = new Date();
  const invoiceDueDate = new Date(invoice.invoice_due_date);
  const invoiceErrorDueDate = new Date(invoiceDueDate).setDate(invoiceDueDate.getDate() + 30);

  return invoice.invoice_status === 'Overdue' && currentDate > invoiceErrorDueDate;
};

class AccountBoxStandalone extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTestVariant: false,
      showEstimationWarning: false,
      limitWarnings: null,
      showLimitWarnings: false,
      remainingTime: {},
      remainingTimeError: ''
    };

    const { user, mostOverdueInvoice } = this.props;
    const { usage: usages, account_type: { limits } = {} } = user;
    const {
      maxLimitType, maxLimitPercentage, isCloseToLimit, maxUsage
    } = findHighestLimit(usages, limits, INLINE_LIMIT_ALERT_TRESHOLD.WARNING);
    const actionManager = new ActionManager({ user, projectName: PROJECT_NAME, trackOnce: true });

    this.maxUsage = maxUsage;
    this.maxLimitType = maxLimitType;
    this.maxLimitPercentage = maxLimitPercentage;
    this.isCloseToLimit = isCloseToLimit;
    this.registerJotformAction = actionManager.registerJotformAction;

    // force InlineLimitAlert & EnterpriseInlineLimitAlert
    const urlParams = new URLSearchParams(window.location.search);
    this.isInlineLimitAlertForced = !!urlParams.get('fila');
    this.isEnterpriseInlineLimitAlertForced = !!urlParams.get('feila');
    this.isEnterpriseInlineLimitAlertV2Forced = !!urlParams.get('feilavt');

    if (this.isInlineLimitAlertForced) {
      const { WARNING, DANGER } = INLINE_LIMIT_ALERT_TRESHOLD;
      this.maxLimitPercentage = urlParams.get('dngr') ? DANGER : WARNING;
    }

    // Display warning message for invoices that are overdue or waiting on a payment. Invoices are passed from the common-header repo if user is an admin
    const warningMessageIsDismissed = readCookie('INVOICE_WARNING') === 'false';
    if (warningMessageIsDismissed) {
      this.invoice = isErrorInvoice(mostOverdueInvoice) ? mostOverdueInvoice : {};
    } else {
      this.invoice = mostOverdueInvoice;
    }
    this.invoiceMessageType = !!Object.keys(this.invoice).length && this.invoice.invoice_status !== 'Paid' && (isErrorInvoice(this.invoice) ? 'error' : 'warning');

    // A/B Test: enterpriseInlineLimitAlertII
    this.abTestHelper = new ABTestManager({
      user,
      isTestEnabled: false,
      testName: 'enterpriseInlineLimitAlertII',
      controlVariantCode: '7661',
      testVariantCode: '7671',
      cacheVariantCodeAtLocalStorage: true,
      urlParam: 'eilat',
      customUserChecks: {
        isGold: isGold(user),
        isUserCloseToLimit: this.isCloseToLimit
      }
      // reminder: dont forget debugMode
      // debugMode: {
      //   logTestState: true
      //   // forceUserEligible: true
      //   // forceTestVariant: true
      //   // forceControlVariant: true
      // }
    });

    this.accountBoxLimitWarningsABTest = new ABTestManager({
      user,
      isTestEnabled: false,
      testName: 'accountBoxLimitWarnings',
      controlVariantCode: '9861',
      testVariantCode: '9871',
      cacheVariantCodeAtLocalStorage: true
    });
  }

  componentDidMount() {
    const {
      user: { usage = {}, account_type: { limits = {}, name: accountTypeName = '' } = {}, limitEstimation = {} }, isEnterprise
    } = this.props;

    this.abTestHelper.isTestVariant().then(result => {
      this.setState({ isTestVariant: result });
      this.abTestHelper?.registerABTestAction({ action: 'seen', target: 'accountBox' });
    }).catch(error => {
      this.setState({ isTestVariant: false });
      console.warn(`${this.abTestHelper.testName} variant error`, error);
    });

    const limitWarnings = calculateLimitWarning({ usage, limits });
    this.setState({
      limitWarnings: limitWarnings
    });

    if (limitWarnings) {
      this.accountBoxLimitWarningsABTest.isTestVariant().then(q => {
        this.setState({
          showLimitWarnings: q
        });
      }).catch(err => {
        this.setState({
          showLimitWarnings: false
        });
        console.log(`${this.accountBoxLimitWarningsABTest.testName} :: Cannot get test variant`, err);
      });
    }

    if (false
        && limitEstimation?.estimationDate
        && limitEstimation?.type
        && shouldShowEstimationWarning({ usage: usage?.[limitEstimation?.type], limit: limits?.[limitEstimation?.type === 'signed_documents' ? 'signedDocuments' : limitEstimation?.type] })
    ) {
      this.setState({ showEstimationWarning: true });
    }

    const { ACCOUNT_BOX_SEEN } = Trackings;
    this.registerJotformAction(ACCOUNT_BOX_SEEN);

    // Get remaining upload space renewal time
    if (false) {
      (async () => {
        if (isEnterprise || accountTypeName === 'ENTERPRISE') {
          return;
        }

        try {
          const time = await getRecalculationRemainingTime();
          this.setState({ remainingTime: time });
        } catch (error) {
          this.setState({ remainingTimeError: error });
        }
      })();
    }
  }

  render() {
    const {
      user: {
        name = '',
        usage: usages = {},
        campaign = {},
        username = '',
        avatarUrl = '',
        account_type: accountType = {},
        language = DEFAULT_LANG
      } = {}
    } = this.props;

    const {
      user,
      appName,
      isFormUser,
      builderType,
      isEnterprise,
      showAdminConsole,
      showUserLimitList,
      handleSwitchToOld,
      isAccountBoxOpened,
      toggleAccountBox,
      logoutURLParameter,
      showAdminConsolePromotion,
      showSettings,
      navigateToMyAccountOnHeaderClick,
      isUpgradeOptionVisible,
      settingsDontUseRootPath
    } = this.props;

    const {
      isTestVariant, showEstimationWarning, limitWarnings, showLimitWarnings, remainingTime, remainingTimeError
    } = this.state;
    const { limits } = accountType;

    // A/B Test: accountBoxAlertTest
    // const wrapperClassName = this.maxLimitPercentage === 100 ? ' jNewHeader-alertAbTest-danger' : '';
    let wrapperClassName = this.isInlineLimitAlertForced ? ' jNewHeader-alertAbTest-danger' : '';
    wrapperClassName = isTestVariant ? ' jNewHeader-enterpriseAlertAbTest-wrapper' : '';

    const adminConsoleLink = window.location.host.split('.').reverse()[0] === 'biz' ? '/enterprise-admin' : '/admin';

    return (
      <div
        className={`jNewHeader-subMenu isWide jNewHeader-designOutlineV1 ${wrapperClassName}`}
        data-testid="accountBoxStandaloneContainer"
      >
        <div className="jNewHeader-subMenuBox">
          {/* name and account type */}
          <Header
            avatarUrl={avatarUrl}
            isFormUser={isFormUser}
            accountType={accountType}
            isEnterprise={isEnterprise}
            displayName={name || username}
            registerJotformAction={this.registerJotformAction}
            navigateToMyAccount={navigateToMyAccountOnHeaderClick}
            settingsDontUseRootPath={settingsDontUseRootPath}
          />
          {getUrlParameter('jfCSS') && (
            <div style={{ padding: '10px' }}>
              <Button
                colorStyle="primary"
                fullWidth
                rounded
                startIcon={IconPrintXmark}
              />
            </div>
          )}
          {false && (!isEmpty(remainingTime) && remainingTimeError === '') && (
            <div className="jNewHeader-uploadSpaceBanner">
              <div className="jNewHeader-uploadSpaceBanner-icon">
                <IconInfoCircleFilled />
              </div>
              <div className="jNewHeader-uploadSpaceBannerDescription">
                {translationRenderer(Texts.SINCE_RECENTLY_DELETED)({
                  renderer1: () => (
                    <span>
                      {remainingTime?.hour !== 0 && `${remainingTime?.hour} ${remainingTime?.hour > 1 ? t('hours') : t('hour')} `}
                      {remainingTime?.minutes >= 1 && remainingTime?.minutes}
                      {' '}
                      {remainingTime?.minutes > 1 ? t('minutes') : t('minute')}
                      {remainingTime?.minutes < 1 && ` ${t('a few seconds')}`}
                    </span>
                  )
                })}
              </div>
            </div>
          )}
          <div className='jNewHeader-accountBoxContent'>
            {/* A/B Test: accountBoxAlertTest */}
            {/* A/B Test: waiting for data analysis */}
            {this.isInlineLimitAlertForced && (
            <InlineLimitAlertWithTrackings
              enterpriseMode={false}
              builderType={builderType}
              limitType={this.maxLimitType}
              limitPercentage={this.maxLimitPercentage}
              registerJotformAction={this.registerJotformAction}
            />
            )}
            {/* enterprise promotion for gold users  */}
            {/* A/B Test: enterpriseInlineLimitAlertII */}
            {(this.isEnterpriseInlineLimitAlertForced
            || this.isEnterpriseInlineLimitAlertV2Forced
            || (this.isCloseToLimit && isGold(user))) && (
            <EnterpriseInlineLimitAlert
              user={user}
              usage={this.maxUsage}
              limitLabel={LIMIT_LABEL_MAP[this.maxLimitType]}
              isTestVariant={isTestVariant || this.isEnterpriseInlineLimitAlertV2Forced}
              registerJotformAction={this.registerJotformAction}
              logAbTestAction={this.abTestHelper?.registerABTestAction}
            />
            )}
            {/* A/B Test :: accountBoxEstimationWarning */}
            {showEstimationWarning && (
              <LimitEstimationWarning
                user={user}
              />
            )}
            {/* A/B Test :: accountBoxLimitWarnings */}
            {limitWarnings && showLimitWarnings && (
              <AccountBoxLimitWarnings
                user={user}
                type={limitWarnings?.type}
                limit={limitWarnings?.limit}
              />
            )}
            {/* limits */}
            {!isEnterprise && !isFormUser && showUserLimitList && (
            <Limits
              user={user}
              usages={usages}
              limits={limits}
              language={language}
              campaign={campaign}
              builderType={builderType}
              isTestVariant={isTestVariant || this.isEnterpriseInlineLimitAlertV2Forced}
              registerJotformAction={this.registerJotformAction}
              logAbTestAction={this.abTestHelper?.registerABTestAction}
              isUpgradeOptionVisible={isUpgradeOptionVisible}
              settingsDontUseRootPath={settingsDontUseRootPath}
            />
            )}
            {/* menu items */}
            <MenuItems
              user={user}
              adminConsoleLink={adminConsoleLink}
              appName={appName}
              isEnterprise={isEnterprise}
              showAdminConsole={showAdminConsole}
              showAdminConsoleWarning={this.invoiceMessageType}
              toggleAccountBox={toggleAccountBox}
              handleSwitchToOld={handleSwitchToOld}
              logoutURLParameter={logoutURLParameter}
              isAccountBoxOpened={isAccountBoxOpened}
              registerJotformAction={this.registerJotformAction}
              registerABTestAction={this.abTestHelper.registerABTestAction}
              showAdminConsolePromotion={showAdminConsolePromotion}
              showSettings={showSettings}
              settingsDontUseRootPath={settingsDontUseRootPath}
            />
            {/* warning message for admins if an invoice is overdue or waiting payment */}
            {!!Object.keys(this.invoice).length && this.invoice.invoice_status !== 'Paid' && (
            <InvoiceWarning
              adminConsoleLink={adminConsoleLink}
              invoice={this.invoice}
              invoiceMessageType={this.invoiceMessageType}
            />
            )}
          </div>
        </div>
      </div>
    );
  }
}

AccountBoxStandalone.propTypes = {
  builderType: string,
  appName: string,
  handleSwitchToOld: oneOfType([
    func,
    bool
  ]),
  isEnterprise: bool.isRequired,
  isAccountBoxOpened: bool.isRequired,
  toggleAccountBox: func.isRequired,
  user: shape({
    account_type: shape({
      name: string,
      limits: shape({})
    }),
    avatarUrl: string,
    username: string,
    usage: shape({}),
    location: shape({}),
    campaign: shape({})
  }).isRequired,
  showUserLimitList: bool,
  logoutURLParameter: string,
  showAdminConsole: bool.isRequired,
  isFormUser: bool,
  mostOverdueInvoice: shape({}),
  showAdminConsolePromotion: bool,
  showSettings: bool,
  navigateToMyAccountOnHeaderClick: bool,
  isUpgradeOptionVisible: bool,
  settingsDontUseRootPath: bool
};

AccountBoxStandalone.defaultProps = {
  builderType: 'card',
  appName: '',
  handleSwitchToOld: false,
  showUserLimitList: true,
  logoutURLParameter: '',
  isFormUser: false,
  mostOverdueInvoice: {},
  showAdminConsolePromotion: undefined,
  showSettings: undefined,
  navigateToMyAccountOnHeaderClick: true,
  isUpgradeOptionVisible: undefined,
  settingsDontUseRootPath: false
};

export default AccountBoxStandalone;
