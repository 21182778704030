import React, { useRef } from 'react';
import { elementType, func } from 'prop-types';
import { Hooks } from '@jotforminc/uikit';
import { t } from '@jotforminc/translation';
import { ALL_TEXTS, userPropType, isGuest } from '@jotforminc/enterprise-promotions-utils';
import PricingBackTooltip from './PricingBackTooltip';
import JotformIcon from '../../assets/svg/enterpriseCheckoutLikeSection/icon.svg';
import JotformLogo from '../../assets/svg/enterpriseCheckoutLikeSection/logo.svg';

const Header = ({
  user,
  AccountBoxRenderer,
  onClose
}) => {
  const avatarRef = useRef();
  const accountBoxWrapperRef = useRef();

  const tooltipRef = useRef();

  const { avatarUrl = '' } = user;
  const [isAccountBoxOpened, setAccountBoxOpened] = Hooks.useClickOutsideState(false, [avatarRef, accountBoxWrapperRef]);
  const [isBackTooltipVisible, setIsBackTooltipVisible] = Hooks.useClickOutsideState(false, [tooltipRef]);

  const handleClick = () => {
    setAccountBoxOpened(opened => !opened);
  };

  const handleLogoClick = () => {
    setIsBackTooltipVisible(true);
  };

  const handleStayClick = () => {
    setIsBackTooltipVisible(false);
  };

  const handleBackClick = () => {
    onClose();
  };

  return (
    <div className="z-2 fixed top-0 right-0 left-0 bg-white">
      <div className="max-w-xl relative w-full p-2 md:p-3 mx-auto flex items-center justify-between">
        <div className="flex items-center justify-center min-h-10 md:min-h-12">
          <button type="button" onClick={handleLogoClick}>
            <JotformIcon className="md:hiddenjf w-8 h-auto" />
          </button>
          <button type="button" onClick={handleLogoClick}>
            <JotformLogo className="hiddenjf md:flex h-auto" style={{ width: '167px' }} />
          </button>
          <div className="h-4 w-px bg-navy-100 mx-4" />
          <span className="font-medium text-xl color-navy-700">{t(ALL_TEXTS.CONTACT_ENTERPRISE)}</span>
          {/* back tooltip */}
          {isBackTooltipVisible && (
          <div
            ref={tooltipRef}
            className="absolute left-2 top-10"
          >
            <PricingBackTooltip
              onStayClick={handleStayClick}
              onBackClick={handleBackClick}
            />
          </div>
          )}
        </div>
        {/* avatar */}
        {!isGuest(user) && (
        <button type="button" onClick={handleClick} ref={avatarRef}>
          <img
            alt="avatar"
            src={avatarUrl}
            className="w-10 md:w-12 h-10 md:h-12 radius-full bg-navy-100"
          />
        </button>
        )}
        {/* account box */}
        {isAccountBoxOpened && (
        <div
          className="absolute right-2 md:right-3 top-14 md:top-18 w-92 bg-white z-1 radius-lg border border-navy-100"
          ref={accountBoxWrapperRef}
          style={{
            filter: 'drop-shadow(-1px 2px 6px rgba(76,79,99,.2))'
          }}
        >
          <div className="account-box-triangle" />
          <AccountBoxRenderer
            user={user}
            isEnterprise={false}
            showAdminConsole={false}
            isAccountBoxOpened={isAccountBoxOpened}
          />
        </div>
        )}
      </div>
      <div className='h-0.5 w-full' style={{ background: 'linear-gradient(90deg, #0A1551 0%, #7457FF 41.67%, #0075E3 58.85%, #FF7B1C 100%)' }} />
      {/* styles */}
      <style>
        {
            `
            .jNewHeader-adminConsolePromotion {
              display: none;
            }
            [data-testid="settingsButton"] {
              display: none;
            }
            .account-box-triangle {
              display: block;
              box-sizing: border-box;
              background-color: #fff;
              background-image: linear-gradient(135deg,#fff 50%,transparent 50%);
              border: 1px solid transparent;
              border-color: #c8ceed transparent transparent #c8ceed;
              border-radius: 2px 0 0;
              width: 12px;
              height: 12px;
              animation: .25s ease-out forwards arrowSlideIn;
              position: absolute;
              top: -6px;
              left: auto;
              right: 17px;
              transform: rotate(45deg);
            }
            `
          }
      </style>
    </div>
  );
};

Header.propTypes = {
  AccountBoxRenderer: elementType,
  user: userPropType.isRequired,
  onClose: func
};

Header.defaultProps = {
  AccountBoxRenderer: null,
  onClose: f => f
};

export default Header;
