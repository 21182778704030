import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { t, translationRenderer } from '@jotforminc/translation';
import { Button } from '../UI';

import { ESTIMATION_WARNING, UPGRADE_NOW } from '../../constants/texts';
import { actionTracker, bsgUtmCreator, mapLimitType } from '../../utils';

const LimitEstimationWarning = ({
  user
}) => {
  const { username = '', account_type: { name: planType = '' }, limitEstimation = {} } = user;
  const assetType = `account-box-${limitEstimation?.type}-estimation-warning`;

  useEffect(() => {
    actionTracker({
      username,
      target: assetType,
      action: 'seen'
    });
  }, []);

  const handleUpgradeClicked = () => {
    actionTracker({
      username,
      target: assetType,
      action: 'upgrade-btn-clicked'
    });

    window.open(`/pricing${bsgUtmCreator({ planType, assetType, campaign: 'estimation' })}`);
  };

  return (
    <section className='px-5 pt-4 bg-navy-25'>
      <div className='flex flex-col items-center gap-3 px-4 py-3 bg-orange-100 radius'>
        <p className='color-orange-600 text-sm text-center'>
          {translationRenderer(ESTIMATION_WARNING)({
            renderer1: () => <span className='font-bold'>{mapLimitType(limitEstimation?.type)}</span>,
            renderer2: () => <span className='font-bold'>{limitEstimation?.estimationDate}</span>
          })}
        </p>
        <Button onClick={() => handleUpgradeClicked()} theme='warning'>{t(UPGRADE_NOW)}</Button>
      </div>
    </section>
  );
};

LimitEstimationWarning.propTypes = {
  user: PropTypes.object
};

LimitEstimationWarning.defaultProps = {
  user: {}
};

export default LimitEstimationWarning;
