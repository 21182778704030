import React from 'react';
import { t } from '@jotforminc/translation';

import '../styles/campaignStyles/black-friday-2023.scss';

export const BlackFridayPromotionRenderer = () => (
  <div className='limit-dialog-blackfriday2023'>
    <div className="top-title">
      {t('Limited time offer!')}
    </div>
    <div className='inner-container'>
      <div className="label-desktop-image">
        <img className='label-desktop' src="https://cdn.jotfor.ms/assets/img/campaigns/2023/blackfriday/limit-dialog/label.svg" alt="" />
      </div>
      <div className='title-container'>
        <div className="title">
          {t('Black Friday')}
        </div>
        <div className="title-2">{t('Save 50%')}</div>
      </div>
      <div className="label-mobile-image">
        <img className='label-mobile' src="https://cdn.jotfor.ms/assets/img/campaigns/2023/blackfriday/limit-dialog/label-2.svg" alt="" />
      </div>
    </div>
  </div>
);
