import React from 'react';
import { node, string } from 'prop-types';

import { handleCustomNavigation } from '@jotforminc/utils';
import { t } from '@jotforminc/translation';

import './v1.scss';

import FlyIn from '../../../../../ui/FlyIn/FlyIn';

import { useFlyinUtm } from '../../../../../../hooks/useUtm';

const G2PromotionFlyInV1 = ({ isSticky, children, ...props }) => {
  let dynamicPagePath = window.location.pathname.split('/').find(pathText => pathText.includes('-alternative'));
  if (!dynamicPagePath) {
    const splitedPaths = window.location.pathname.split('/').filter(path => path);
    dynamicPagePath = splitedPaths[splitedPaths.length - 1];
  }

  const { url } = useFlyinUtm({
    directURL: 'https://link.jotform.com/RjKWlgCgzJ',
    customUtmCampaign: dynamicPagePath
  });

  const handleSeeJotformOnG2Btn = () => {
    handleCustomNavigation(url, '_blank', true);
  };

  return (
    <FlyIn
      isSticky={isSticky}
      CloseIconRenderer={() => <img src="https://cdn.jotfor.ms/assets/img/campaigns/typeform-flyin/close-red.svg" alt="Close Icon" />}
      {...props}
    >
      <div className="flyin-content typeform-alternative-flyin" data-jfa="typeform-alternative-flyin">
        <div className="star-start">
          <img src="https://cdn.jotfor.ms/assets/img/campaigns/typeform-flyin/star-1.svg" alt="Stars" />
        </div>
        <div className="inner">
          <div className="badges">
            <img src="https://cdn01.jotfor.ms/assets/img/campaigns/typeform-flyin/badges.svg?v=0.1" alt="Badges" />
            <div className="star-center">
              <img src="https://cdn.jotfor.ms/assets/img/campaigns/typeform-flyin/star-2.svg" alt="Stars" />
            </div>
          </div>
          <div className="text">
            <div className="badge-and-text">
              { children || (
                <>
                  <div className="logo">
                    <img src="https://cdn.jotfor.ms/assets/img/campaigns/typeform-flyin/logo-navy.svg" alt="Jotform Logo" />
                  </div>
                  <strong className="is-text">is a </strong>
                  <strong className="is-main-text">trusted G2 leader</strong>
                </>
              )}
            </div>
            <div className="stars-image">
              <img src="https://cdn01.jotfor.ms/assets/img/campaigns/typeform-flyin/stars.svg" alt="g2 stars" />
            </div>
          </div>
          <button type='button' className="cta-button" onClick={handleSeeJotformOnG2Btn}>
            <div className="end-star">
              <img src="https://cdn.jotfor.ms/assets/img/campaigns/typeform-flyin/star-3.svg" alt="Stars" />
            </div>
            <a href="#">{t('See Jotform on G2')}</a>
          </button>
        </div>
      </div>
    </FlyIn>
  );
};

G2PromotionFlyInV1.propTypes = {
  isSticky: string.isRequired,
  children: node
};

G2PromotionFlyInV1.defaultProps = {
  children: undefined
};

export default G2PromotionFlyInV1;
