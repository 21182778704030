import React from 'react';
import { string } from 'prop-types';

import { handleCustomNavigation } from '@jotforminc/utils';

import { t, translationRenderer } from '@jotforminc/translation';

import './v1.scss';

import FlyIn from '../../../../../ui/FlyIn/FlyIn';

import { useFlyinUtm } from '../../../../../../hooks/useUtm';

const MicrosoftTeamsFlyInV1 = ({ isSticky, ...props }) => {
  const handleClose = () => {
    console.log('action: flyin close');
  };

  const { url } = useFlyinUtm({
    directURL: '/blog/announcing-jotform-for-microsoft-teams/',
    customUtmCampaign: 'microsoft-teams'
  });

  const handleClick = () => {
    handleCustomNavigation(url, '_blank', true);
  };

  return (
    <FlyIn
      onClose={handleClose}
      CloseIconRenderer={() => <img className="close-teams" src="https://cdn.jotfor.ms/assets/img/campaigns/2024/teams/flyin/close.svg" alt="Close Icon" />}
      isSticky={isSticky}
      {...props}
    >
      <div className="teams-flyin-wrapper" data-jfa="teams-flyin">
        <div className="teams-flyin-wrapper-container">
          <img
            width="120" height="60" className="teams-img"
            src="https://cdn.jotfor.ms/assets/img/campaigns/2024/teams/flyin/teams.svg?v=0.1" alt="Microsoft Teams"
          />
          <div className="title-area">
            <span>
              {translationRenderer('Announcing [1[Microsoft Teams Integration]]')({
                renderer1: teams => (<strong>{teams}</strong>)
              })}
            </span>
          </div>
          <button
            className="action-link locale" type="button" onClick={handleClick}
          >
            {t('Discover Now')}
          </button>
        </div>
      </div>
    </FlyIn>
  );
};

MicrosoftTeamsFlyInV1.propTypes = {
  isSticky: string.isRequired
};

export default MicrosoftTeamsFlyInV1;
