import React from 'react';
import {
  bool, func, shape, string
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { handleCustomNavigation } from '@jotforminc/utils';
import { Texts, Trackings } from '../../constants';

const Header = ({
  isFormUser,
  avatarUrl,
  displayName,
  accountType,
  isEnterprise,
  registerJotformAction,
  navigateToMyAccount,
  settingsDontUseRootPath
}) => {
  const safeAvatarUrl = (avatarUrl && avatarUrl.length) ? avatarUrl.replace('http://', 'https://') : '';
  const avatarStyle = safeAvatarUrl ? { backgroundImage: `url("${safeAvatarUrl}")` } : {};

  const shouldShowPlanBadge = () => {
    if (isFormUser) return false;
    if (isEnterprise && accountType?.name === 'ENTERPRISE') return false;
    return true;
  };

  const getPlanBadgeClassName = () => {
    return `jNewHeader-userAccountBox-accountType planName-${accountType?.name?.toLowerCase()}`;
  };

  const getPlanBadgeText = () => {
    return accountType?.name === 'FREE' ? t(Texts.STARTER) : t(accountType.name);
  };

  const handleClick = e => {
    e.preventDefault();

    if (!navigateToMyAccount) return;

    const { HEADER_CLICK } = Trackings;
    registerJotformAction(HEADER_CLICK);
    handleCustomNavigation('/myaccount/', '_blank', settingsDontUseRootPath);
  };

  // eslint-disable-next-line react/prop-types
  const Container = ({ children }) => (
    navigateToMyAccount
      ? (
        <button
          type="button"
          className="jNewHeader-userAccountBox"
          onClick={handleClick}
        >
          {children}
        </button>
      )
      : (
        <div
          className="jNewHeader-userAccountBox"
          style={{ cursor: 'initial' }}
        >
          {children}
        </div>
      )
  );

  return (
    <Container>
      {/* avatar */}
      {!isFormUser && (
        <div
          id="jNewHeaderAvatar"
          data-testid="avatar"
          style={avatarStyle}
          className={`jNewHeader-userAccountBox-avatar ${safeAvatarUrl ? '' : 'empty'}`}
        />
      )}
      <div className='jNewHeader-userAccountBox-helloWrapper'>
        {/* salute */}
        <span
          data-testid="salute"
          className="jNewHeader-userAccountBox-salute"
        >
          {t(Texts.SALUTE)}
        </span>
        {/* name */}
        <span
          id="jNewHeaderUsername"
          data-testid="userNameText"
          className="jNewHeader-userAccountBox-userName"
        >
          {displayName}
        </span>
      </div>
      {/* plan badge */}
      {shouldShowPlanBadge(accountType, isFormUser, isEnterprise) && (
        <div
          data-testid="planBadge"
          className={getPlanBadgeClassName(accountType)}
        >
          {getPlanBadgeText(accountType)}
        </div>
      )}
    </Container>
  );
};

Header.propTypes = {
  isFormUser: bool.isRequired,
  avatarUrl: string.isRequired,
  displayName: string.isRequired,
  accountType: shape({}).isRequired,
  isEnterprise: bool.isRequired,
  registerJotformAction: func.isRequired,
  navigateToMyAccount: bool.isRequired,
  settingsDontUseRootPath: bool
};

Header.defaultProps = {
  settingsDontUseRootPath: false
};

export default Header;
