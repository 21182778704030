/* eslint-disable max-len */
import React, { useMemo, useState } from 'react';
import {
  bool,
  func, number, shape, string
} from 'prop-types';
import { t } from '@jotforminc/translation';
import { handleCustomNavigation } from '@jotforminc/utils';
import { IconChevronDown } from '@jotforminc/svg-icons';
import {
  createLimitItemsRenderingData, findHighestLimit, isArrayHasItem, isFree
} from '../../utils';
import {
  Texts, LIMIT_KEY_MAP, USAGE_KEY_MAP, Trackings
} from '../../constants';
import Limit from './Limit';
import UpgradeButton from './UpgradeButton';

const Limits = ({
  user,
  limits,
  usages,
  campaign,
  language,
  builderType,
  isTestVariant,
  logAbTestAction,
  registerJotformAction,
  isUpgradeOptionVisible,
  settingsDontUseRootPath
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { isCloseToLimit } = useMemo(() => findHighestLimit(usages, limits, 70), [usages, limits]);
  const { UPGRADE_YOUR_PLAN_CLICK, VIEW_ALL_CLICK } = Trackings;

  const { type: campaignType, industryCampaign, industryCampaignAssetVersion } = campaign;

  const handleViewAll = e => {
    e.stopPropagation();
    setIsExpanded(expanded => !expanded);
    logAbTestAction({ action: 'click', target: 'accountBox-viewAll' });
    registerJotformAction(VIEW_ALL_CLICK);
  };

  const [firstPart, remainingPart] = createLimitItemsRenderingData(usages, limits);

  if (!isArrayHasItem(firstPart) || !isArrayHasItem(remainingPart)) return null;

  const getExpantionText = () => {
    if (!isTestVariant) {
      return !isExpanded ? t(Texts.VIEW_ALL) : t(Texts.VIEW_LESS);
    }
    return !isExpanded ? t(Texts.VIEW_LIMIT_USAGE) : t(Texts.CLOSE_LIMIT_USAGE);
  };

  const getUkNewUserCampaignAvailability = () => {
    if (global?.location?.href?.includes('ukNewUserCampaign=1')) return true;

    const { type = '', assetsAvailable = false, assetsVersion } = campaign || {};
    if (type !== 'NEWUSER' || !assetsAvailable) return false;
    return assetsVersion === 'v2';
  };

  const getOverquotaUserCampaignAvailability = () => {
    const { type = '', assetsAvailable = false } = campaign || {};
    return assetsAvailable && type === 'OVERQUOTAUSER';
  };
  const getBtp2023CampaignAvailability = () => {
    const { type = '', assetsAvailable = false } = campaign || {};
    return assetsAvailable && type === 'BACKTOPAID';
  };
  const isOverquotaCampaignActive = getOverquotaUserCampaignAvailability();
  const isBtp2023CampaignActive = getBtp2023CampaignAvailability();
  const isUKNewUserCampaignActive = getUkNewUserCampaignAvailability() || isOverquotaCampaignActive;
  const isBlackFriday = campaignType === 'BLACKFRIDAY';
  const enableChurch = industryCampaign === 'CHURCH' && industryCampaignAssetVersion === 'v2';
  const enableAnimalShelter = industryCampaign === 'ANIMAL-SHELTER' && industryCampaignAssetVersion === 'v2';

  const handleUpgradeOptionClick = () => {
    let utmCampaign = builderType === 'card' ? 'upgrade-from-account-box-editor_cf' : 'upgrade-from-account-box-editor';
    if (isUKNewUserCampaignActive && !isOverquotaCampaignActive && isBtp2023CampaignActive) {
      utmCampaign += '-newUserDesingTest';
    }

    if (isOverquotaCampaignActive || isBtp2023CampaignActive || isBlackFriday) {
      utmCampaign += `-${campaign?.utm_campaign}`;
    }

    let pricingURL = `/pricing/?utm_source=jNewHeader&utm_medium=banner&utm_campaign=${utmCampaign}&utm_term=Upgrade%20for%20more&utm_content=Upgrade%20Now`;

    if (enableChurch) {
      pricingURL = `/online-church/pricing/?utm_campaign=church-2024&utm_source=jNewHeader&utm_content=Upgrade%20Now&utm_term=${user?.username}`;
    }
    if (enableAnimalShelter) {
      pricingURL = `/animal-shelter/pricing/?utm_campaign=animal-shelter&utm_source=jNewHeader&utm_content=Upgrade%20Now&utm_term=${user?.username}`;
    }

    handleCustomNavigation(pricingURL, '_blank', settingsDontUseRootPath);

    if (!isUKNewUserCampaignActive) {
      logAbTestAction({ action: 'click', target: 'accountBox-upgradeOption' });
      registerJotformAction(UPGRADE_YOUR_PLAN_CLICK);
    }
  };

  return (
    <ul
      data-testid="userLimitList"
      className={`jNewHeader-userLimitList${isExpanded ? ' isVisible' : ''}`}
    >
      {!isTestVariant && firstPart.map(data => <Limit key={data.name} language={language} {...data} />)}
      <li
        className="jNewHeader-userLimitCollapse"
        aria-hidden={isExpanded ? 'false' : 'true'}
      >
        <ul style={{ paddingLeft: 0 }}>
          {!isTestVariant && remainingPart.map(data => (
            <Limit
              key={data.name}
              language={language}
              {...data}
            />
          ))}
          {isTestVariant && [...firstPart, ...remainingPart].map(data => (
            <Limit
              key={data.name}
              language={language}
              {...data}
            />
          ))}
        </ul>
      </li>
      {/* expand button */}
      <li className='jNewHeader-userLimitCollapseLinkOutline'>
        <button
          className='jNewHeader-userLimitCollapseLink'
          type='button'
          aria-expanded={isExpanded ? 'true' : 'false'}
          onClick={handleViewAll}
        >
          {getExpantionText()}
          <IconChevronDown />
        </button>
      </li>
      {/* upgrade button */}
      {isUpgradeOptionVisible && !isTestVariant && (isCloseToLimit || isFree(user) || isUKNewUserCampaignActive) && (
      <UpgradeButton
        campaign={campaign}
        onClick={handleUpgradeOptionClick}
        isUKNewUserCampaignActive={isUKNewUserCampaignActive}
        user={user}
      />
      )}
    </ul>
  );
};

Limits.propTypes = {
  user: shape({}).isRequired,
  usages: shape({
    [USAGE_KEY_MAP.TOTAL_SUBMISSONS]: string,
    [USAGE_KEY_MAP.PAYMENTS]: string,
    [USAGE_KEY_MAP.FORMS]: string,
    [USAGE_KEY_MAP.SIGNED_DOCUMENTS]: string,
    [USAGE_KEY_MAP.WORKFLOW_RUNS]: string,
    [USAGE_KEY_MAP.SUBMISSONS]: string,
    [USAGE_KEY_MAP.UPLOAD_SPACE]: string,
    [USAGE_KEY_MAP.FORM_VIEWS]: string
  }).isRequired,
  limits: shape({
    [LIMIT_KEY_MAP.TOTAL_SUBMISSONS]: number,
    [LIMIT_KEY_MAP.PAYMENTS]: number,
    [LIMIT_KEY_MAP.FORMS]: number,
    [LIMIT_KEY_MAP.SIGNED_DOCUMENTS]: number,
    [USAGE_KEY_MAP.WORKFLOW_RUNS]: number,
    [LIMIT_KEY_MAP.SUBMISSONS]: number,
    [LIMIT_KEY_MAP.UPLOAD_SPACE]: number,
    [LIMIT_KEY_MAP.FORM_VIEWS]: number
  }).isRequired,
  language: string.isRequired,
  campaign: shape({}).isRequired,
  builderType: string.isRequired,
  logAbTestAction: func.isRequired,
  registerJotformAction: func.isRequired,
  isTestVariant: bool,
  isUpgradeOptionVisible: bool,
  settingsDontUseRootPath: bool
};

Limits.defaultProps = {
  isTestVariant: false,
  isUpgradeOptionVisible: true,
  settingsDontUseRootPath: false
};

export default Limits;
