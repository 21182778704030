import React from 'react';
import {
  bool, func, oneOfType, string
} from 'prop-types';
import { t } from '@jotforminc/translation';
import cx from 'classnames';
import {
  PrefilledLeadFormAside, ALL_TEXTS, ASSET_IDENTIFIER, GA_AD_FORM_TRACKING_ITEMS, GA_FORM_TRACKING_ITEMS, userPropType,
  createGaAttributes, hasAdwordsModeOn, mapCallbacksToGaTrackingItems, setEPActions
} from '@jotforminc/enterprise-promotions-utils';
import PlanDetails from './PlanDetails';
import Discounts from './Discounts';
import LeadingCompanies from './LeadingCompanies';

const FormStep = ({
  user, onFormSubmit, logAbTestAction, testVariant
}) => {
  const { AB_TEST: { ENTERPRISE_CHECKOUT_LIKE_SECTION: target } } = ASSET_IDENTIFIER;

  const isAdForm = hasAdwordsModeOn();
  let { [target]: gaFormTrackingItems } = isAdForm ? GA_AD_FORM_TRACKING_ITEMS : GA_FORM_TRACKING_ITEMS;

  const gaAttributes = createGaAttributes(target);

  const handleFormSubmit = () => {
    const actionData = { target: 'leadForm', action: 'formSubmit' };

    logAbTestAction(actionData);
    setEPActions({ asset: target, ...actionData });

    onFormSubmit();
  };

  const handleSendRequestClick = () => {
    const actionData = { target: 'sendRequestButton', action: 'click' };

    logAbTestAction(actionData);
    setEPActions({ asset: target, ...actionData });
  };

  gaFormTrackingItems = mapCallbacksToGaTrackingItems(gaFormTrackingItems, [handleSendRequestClick]);

  const leftColumStyles = cx('flex flex-col grow-1 px-4 md:pr-8 lg:pr-16 py-6 md:pt-16');
  const rightColumnStyles = cx('flex flex-col grow-0 w-full md:max-w-92 px-4 md:pl-0 py-6 md:pt-16');

  const shouldRenderHubspotCalendar = () => {
    const params = new URLSearchParams(window.location.search);
    return params.has('ephszh');
  };

  return (
    <div className='flex flex-col md:flex-row justify-center mx-auto w-full max-w-xl h-full overflow-auto'>
      {/* left column */}
      <div
        className={leftColumStyles}
      >
        {/* plan details */}
        <PlanDetails logAbTestAction={logAbTestAction} />
        <div
          className={`iframe-wrapper relative w-full ${isAdForm ? 'xs:px-10' : ''}`} style={shouldRenderHubspotCalendar() ? {
            padding: 16, borderRadius: 8, background: '#f5f8fa', overflow: 'hidden', width: '100%', margin: 0
          } : {}}
        >
          {/* form */}
          {
            shouldRenderHubspotCalendar()
              ? (
                <iframe
                  src='https://meetings.hubspot.com/ziad-hindi/local-data-residency' title='Hubspot Calendar | Austin Michael' className="iframe-height border-none w-full"
                  style={{ minHeight: 860 }}
                />
              )
              : (
                <PrefilledLeadFormAside
                  user={user}
                  onFormSubmit={handleFormSubmit}
                  gaAttributes={gaAttributes}
                  gaFormTrackingItems={gaFormTrackingItems}
                  formUiModifier={target}
                  title={t(ALL_TEXTS.ENTERPRISE_CONTACT_FORM)}
                  classNames="iframe-height border-none w-full"
                  isAdForm={isAdForm}
                />
              )
          }
        </div>
      </div>
      {/* right column */}
      <div className={rightColumnStyles}>
        <Discounts />
        <LeadingCompanies logAbTestAction={logAbTestAction} testVariant={testVariant} />
      </div>
    </div>
  );
};

FormStep.propTypes = {
  user: userPropType.isRequired,
  onFormSubmit: func,
  logAbTestAction: func,
  testVariant: oneOfType([string, bool])
};

FormStep.defaultProps = {
  logAbTestAction: f => f,
  onFormSubmit: f => f,
  testVariant: false
};

export default FormStep;
