import React from 'react';
import { number, string } from 'prop-types';
import { translationRenderer } from '@jotforminc/translation';
import { Texts } from '../../constants';
import { getLimitText } from '../../utils';

const UsageInfo = ({
  name,
  usage,
  limit,
  language
}) => {
  const limitText = getLimitText(limit);

  return (
    <span data-testid={`${name}Usage`}>
      {limitText === Texts.USAGE_INFO && translationRenderer(limitText)({
        renderer1: () => (<strong key={usage}>{usage?.toLocaleString(language)}</strong>),
        renderer2: () => (<span key={limit}>{limit?.toLocaleString(language)}</span>)
      })}
      {limitText === Texts.USAGE_INFO_UNLIMITED && translationRenderer(limitText)({
        renderer1: () => (<strong key={usage}>{usage?.toLocaleString(language)}</strong>)
      })}
    </span>
  );
};

UsageInfo.propTypes = {
  name: string.isRequired,
  usage: number.isRequired,
  limit: number.isRequired,
  language: string.isRequired
};

export default UsageInfo;
