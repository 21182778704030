import { t, translationRenderer } from '@jotforminc/translation';
import {
  bool, func, number, string
} from 'prop-types';
import React, { useEffect } from 'react';
import { IconStarFilled } from '@jotforminc/svg-icons';
import {
  GA_SUFFIXES, createGaAttributes, formatNumber, saveCustomerAsHubspotFormByUsername, setEPActions,
  ASSET_IDENTIFIER, ALL_TEXTS, CTA_URLS, SDR_SOURCES
} from '@jotforminc/enterprise-promotions-utils';
import IconEnterprise from '../../assets/iconEnterprise.svg';
import './limitBanner.scss';

const LimitBanner = ({
  usage,
  onSeen,
  limitLabel,
  isTestVariant,
  onPrimaryClick,
  onSecondaryClick
}) => {
  const {
    ENTERPRISE_ACCOUNTBOX_PROMOTION: {
      CONTACT_SALES_URL,
      LEARN_MORE_URL
    }
  } = CTA_URLS;

  const { PRODUCT: { LIMIT_BANNER: target } } = ASSET_IDENTIFIER;
  const gaAttributes = createGaAttributes(target);
  const gaAttributesCs = createGaAttributes(target, GA_SUFFIXES.CONTACT_SALES);
  const gaAttributesLm = createGaAttributes(target, GA_SUFFIXES.LEARN_MORE);

  const usagePhrase = `${formatNumber(usage)} ${limitLabel}`;

  useEffect(() => {
    onSeen();

    setEPActions({ asset: target, target, action: 'seen' });
  }, []);

  const handleContactSalesClick = () => {
    onPrimaryClick();

    setEPActions({ asset: target, target: 'contactSalesButton', action: 'click' });
    saveCustomerAsHubspotFormByUsername({ lastProductAssetInteraction: SDR_SOURCES.ACCOUNT_BOX_ENTERPRISE_PROMO });
  };

  const handleLearnMoreClick = () => {
    onSecondaryClick();

    setEPActions({ asset: target, target: 'learnMoreButton', action: 'click' });
    saveCustomerAsHubspotFormByUsername({ lastProductAssetInteraction: SDR_SOURCES.ACCOUNT_BOX_ENTERPRISE_PROMO });
  };

  const wrapperClassName = !isTestVariant ? 'jNewHeader-enterprise-promotion' : 'jNewHeader-enterprise-promotion test-variant';

  return (
    <div
      {...gaAttributes}
      className={wrapperClassName}
      role="alert"
    >
      <div className="header">
        {/* control variant */}
        {!isTestVariant && (
          <>
            <IconEnterprise className="icon" />
            <div>
              <h5 className="title">{t(ALL_TEXTS.GET_RID_OF_LIMITS)}</h5>
              <p className="description">{t(ALL_TEXTS.EXCLUSIVE_ENTERPRISE_FEATURES)}</p>
            </div>
          </>
        )}
        {/* test variant */}
        {isTestVariant && (
          <>
            <div className="header-wrapper">
              <span className="icon-wrapper">
                <IconStarFilled className="icon" style={{ fill: '#596ED7' }} />
              </span>
              <h5 className="title">{t(ALL_TEXTS.CONGRATULATIONS)}</h5>
            </div>
            <p className="description">
              {translationRenderer(ALL_TEXTS.YOU_MAY_CONSIDER_UPGRADING)({
                renderer1: () => <strong key="usageText">{usagePhrase}</strong>,
                renderer2: enterpriseText => <strong key="enterpriseText">{enterpriseText}</strong>
              })}
            </p>
          </>
        )}
      </div>
      <div className="button-wrapper">
        {/* contact sales */}
        <a
          {...gaAttributesCs}
          target="_blank"
          className="contact"
          href={CONTACT_SALES_URL}
          onClick={handleContactSalesClick}
        >
          {t(ALL_TEXTS.CONTACT_SALES)}
        </a>
        {/* learn more */}
        <a
          {...gaAttributesLm}
          target="_blank"
          className="learn-more"
          href={LEARN_MORE_URL}
          onClick={handleLearnMoreClick}
        >
          {t(ALL_TEXTS.LEARN_MORE)}
        </a>
      </div>
    </div>
  );
};

LimitBanner.propTypes = {
  usage: number,
  limitLabel: string,
  isTestVariant: bool,
  onSeen: func,
  onPrimaryClick: func,
  onSecondaryClick: func
};

LimitBanner.defaultProps = {
  usage: 0,
  limitLabel: '',
  isTestVariant: false,
  onSeen: f => f,
  onPrimaryClick: f => f,
  onSecondaryClick: f => f
};

export default LimitBanner;
