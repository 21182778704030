/* eslint-disable max-len */
import React from 'react';

import { t } from '@jotforminc/translation';

import './v1.scss';

import ExitModal from '../../../../../ui/ExitModal/ExitModal';

const SilverOneExitModal = () => {
  const onClose = () => {
    console.log('action: onClose');
  };

  const handleClick = () => {
    console.log('action: handleClick');
  };

  return (
    <ExitModal
      onClose={onClose}
      title="Silver One Exit Modal"
    >
      <div className="exit-modal-content">
        <div className="exit-modal--area">
          <div className="exit-modal--container">
            <div className="exit-modal--title">
              <div className="badge">
                <span className="badge-inner">
                  {t('LIMITED TIME OFFER')}
                </span>
              </div>
              <p className="exit-modal--title-h2">
                {t('Silver Plan')}
              </p>
              <p className="exit-modal--title-h1">
                {t('Only $1')}
              </p>
              <p className="exit-modal--title-h4">
                {t('FOR A YEAR')}
                *
              </p>
              <p className="exit-modal--title-h3">
                {t('Get more than twice as many monthly by upgrading to a Silver plan.')}
              </p>
            </div>
            <img className="money" src="https://cdn.jotfor.ms/assets/img/campaigns/2024/only-one/exit-intent/money.png" alt="Jotform Silver One Campaign" />
            <div className="exit-modal--list">
              <div className="exit-modal--list-h1">
                {t('What you get')}
              </div>
              <div className="exit-modal--list-text">
                <p>
                  <img className="exit-modal--list-text-img" src="https://cdn.jotfor.ms/assets/img/campaigns/2024/only-one/exit-intent/icon-check.svg" alt="Jotform Silver One Check Icon" />
                  <span>{t('Double the form creation limit')}</span>
                </p>
                <p>
                  <img className="exit-modal--list-text-img" src="https://cdn.jotfor.ms/assets/img/campaigns/2024/only-one/exit-intent/icon-check.svg" alt="Jotform Silver One Check Icon" />
                  <span>{t('Collect more responses every month')}</span>
                </p>
                <p>
                  <img className="exit-modal--list-text-img" src="https://cdn.jotfor.ms/assets/img/campaigns/2024/only-one/exit-intent/icon-check.svg" alt="Jotform Silver One Check Icon" />
                  <span>{t('Store 10x more data and files')}</span>
                </p>
                <p>
                  <img className="exit-modal--list-text-img" src="https://cdn.jotfor.ms/assets/img/campaigns/2024/only-one/exit-intent/icon-check.svg" alt="Jotform Silver One Check Icon" />
                  <span>{t('Boost visibility and engagement')}</span>
                </p>
              </div>
            </div>
            <div className="exit-modal--title">
              <button className="exit-modal--cta js-exit-modal-cta" type="button" onClick={handleClick}>
                {t('Upgrade now - Only $1')}
              </button>
              <p className="exit-modal--title-h5">
                *
                {t('Offer does not apply to Jotform Enterprise. The $1 offer is a one-time offer for Bronze users upgrading to a Silver plan. Plans will switch to Silver for the remainder of the existing Bronze billing cycle and be charged at the price of an annual Silver plan at the end of the billing cycle. Cannot be combined with other offers.')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </ExitModal>
  );
};

export default SilverOneExitModal;
