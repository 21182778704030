import React, { useEffect, useMemo, useState } from 'react';
import { func, node, object } from 'prop-types';

import { ActionManager } from '@jotforminc/abtest-manager';
import { handleCustomNavigation } from '@jotforminc/utils';

import './account-box.scss';

import { useAssetInfo } from '../../../hooks/useCampaignInfo';

const AccountBox = ({
  user,
  children,
  onClick
}) => {
  const actionManager = useMemo(() => new ActionManager({ user, projectName: 'growthAssetManager', enableDebugMode: false }), [user]);
  const [seen, setSeen] = useState(false);

  const {
    utm_campaign: utmCampaign,
    type: campaignType,
    assetsVersion,
    assetYear
  } = useAssetInfo();

  const _target = `AccountBox${utmCampaign}-${assetsVersion}-${assetYear}`;

  const handleClick = () => {
    onClick();
    actionManager.registerJotformAction({ action: 'click', target: _target });
    handleCustomNavigation(`/pricing/?utm_source=jNewHeader&utm_medium=banner&utm_campaign=${utmCampaign}&utm_term=Upgrade%20for%20more&utm_content=Upgrade%20Now`, '_self', true);
  };

  useEffect(() => {
    if (!seen) {
      setSeen(true);
      actionManager.registerJotformAction({ action: 'seen', target: _target });
    }
  }, [seen]);

  return (
    <button
      type="button" onClick={handleClick} className="jfRHeader--account-box"
      data-campaign-type={campaignType}
    >
      {children}
    </button>
  );
};

AccountBox.propTypes = {
  user: object.isRequired,
  children: node.isRequired,
  onClick: func
};

AccountBox.defaultProps = {
  onClick: () => {}
};

export default AccountBox;
