import React, { useEffect } from 'react';
import { t } from '@jotforminc/translation';
import { func } from 'prop-types';
import { Button } from '@jotforminc/uikit';
import { ALL_TEXTS, ASSET_IDENTIFIER, setEPActions } from '@jotforminc/enterprise-promotions-utils';
import './pricingBackTooltip.scss';

const PricingBackTooltip = ({
  onStayClick,
  onBackClick
}) => {
  const { AB_TEST: { ENTERPRISE_CHECKOUT_LIKE_SECTION: target } } = ASSET_IDENTIFIER;

  useEffect(() => {
    setEPActions({ asset: target, target: 'backTooltip', action: 'seen' });

    return () => {
      setEPActions({ asset: target, target: 'backTooltip', action: 'close' });
    };
  }, []);

  const handleStayClick = () => {
    onStayClick();
    setEPActions({ asset: target, target: 'stayButton-backTooltip', action: 'click' });
  };

  const handleBackClick = () => {
    onBackClick();
    setEPActions({ asset: target, target: 'goToPricingButton-backTooltip', action: 'click' });
  };

  return (
    <div className="pricing-back-tooltip">
      <p>{t(ALL_TEXTS.SURE_TO_GO_BACK)}</p>

      <div className='button-wrapper'>
        <Button
          type="button"
          className="pricing-cta"
          aria-label={t(ALL_TEXTS.GO_TO_PRICING)}
          onClick={handleBackClick}
        >
          {t(ALL_TEXTS.GO_TO_PRICING)}
        </Button>
        <Button
          type="button"
          className="check-out-cta"
          aria-label={t(ALL_TEXTS.STAY)}
          onClick={handleStayClick}
        >
          {t(ALL_TEXTS.STAY)}
        </Button>
      </div>
    </div>
  );
};

PricingBackTooltip.propTypes = {
  onStayClick: func,
  onBackClick: func
};

PricingBackTooltip.defaultProps = {
  onStayClick: f => f,
  onBackClick: f => f
};

export default PricingBackTooltip;
