import React from 'react';
import { number, string } from 'prop-types';
import { translationRenderer } from '@jotforminc/translation';
import { Texts } from '../../constants';
import { getUploadLimitText } from '../../utils';

const UploadUsageInfo = ({
  name,
  usage,
  limit
}) => {
  const {
    uploadUsage, uploadUsageUnit, uploadLimit, uploadLimitUnit
  } = getUploadLimitText(usage, limit);

  let uploadSpaceUsageInfo = Texts.UPLOAD_SPACE_USAGE_INFO_DEFAULT;

  if ([0, 1].includes(parseInt(uploadUsage, 10)) && uploadUsageUnit.toLocaleLowerCase() === Texts.BYTES) {
    uploadSpaceUsageInfo = Texts.UPLOAD_SPACE_USAGE_INFO_BYTE;
  } else if (parseInt(uploadUsage, 10) >= 0 && uploadUsageUnit.toLocaleLowerCase() === Texts.BYTES) {
    uploadSpaceUsageInfo = Texts.UPLOAD_SPACE_USAGE_INFO_BYTES;
  }

  return (
    <span data-testid={`${name}Usage`}>
      {/* for byte usage unit - singular */}
      {uploadSpaceUsageInfo === Texts.UPLOAD_SPACE_USAGE_INFO_BYTE
      && translationRenderer(uploadSpaceUsageInfo)({
        renderer1: () => (<strong key="uploadUsage">{uploadUsage}</strong>),
        renderer2: usageUnit => (<strong key="uploadUsageUnit">{usageUnit}</strong>),
        renderer3: () => (<span key="uploadLimit">{uploadLimit}</span>),
        renderer4: () => (<span key="uploadLimitUnit">{uploadLimitUnit}</span>)
      })}
      {/* for bytes usage unit - plural */}
      {uploadSpaceUsageInfo === Texts.UPLOAD_SPACE_USAGE_INFO_BYTES
      && translationRenderer(uploadSpaceUsageInfo)({
        renderer1: () => (<strong key="uploadUsage">{uploadUsage}</strong>),
        renderer2: usageUnit => (<strong key="uploadUsageUnit">{usageUnit}</strong>),
        renderer3: () => (<span key="uploadLimit">{uploadLimit}</span>),
        renderer4: () => (<span key="uploadLimitUnit">{uploadLimitUnit}</span>)
      })}
      {/* for default usage unit - KB, MB, GB, TB */}
      {uploadSpaceUsageInfo === Texts.UPLOAD_SPACE_USAGE_INFO_DEFAULT
      && translationRenderer(uploadSpaceUsageInfo)({
        renderer1: () => (<strong key="uploadUsage">{uploadUsage}</strong>),
        renderer2: () => (<strong key="uploadUsageUnit">{uploadUsageUnit}</strong>),
        renderer3: () => (<span key="uploadLimit">{uploadLimit}</span>),
        renderer4: () => (<span key="uploadLimitUnit">{uploadLimitUnit}</span>)
      })}
    </span>
  );
};

UploadUsageInfo.propTypes = {
  name: string.isRequired,
  usage: number.isRequired,
  limit: number.isRequired
};

export default UploadUsageInfo;
