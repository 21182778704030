import React, { useMemo } from 'react';
import { shape, string } from 'prop-types';
import { t, translationRenderer } from '@jotforminc/translation';
import { handleCustomNavigation, prettyDate } from '@jotforminc/utils';
import { Texts } from '../../constants';

export function InvoiceWarning(props) {
  const { invoice, invoiceMessageType, adminConsoleLink } = props;

  const invoiceFinalDueDate = useMemo(() => {
    const dueDate = new Date(invoice.invoice_due_date);
    // If the status is overdue, push the due date back 33 days which is the maximum grace period we would give
    return new Date(dueDate.setDate(dueDate.getDate() + (invoice.invoice_status === 'Overdue' ? 33 : 0)));
  }, [invoice.invoice_due_date, invoice.invoice_status]);

  return (
    <div
      className={`jNewHeader-invoice ${invoiceMessageType}`}
      data-testid={`invoice-status-${invoiceMessageType}`}
    >
      <div className="invoice-details-text">
        <p className="invoice-status">
          {translationRenderer(Texts.INVOICE_STATUS)({
            renderer1: () => (invoice.invoice_status === 'Overdue' ? 'Overdue' : 'Available')
          })}
        </p>
        <p className="invoice-due">
          {translationRenderer(Texts.INVOICE_DUE_DATE)({
            renderer1: () => prettyDate(invoiceFinalDueDate, false, true, true)
          })}
        </p>
      </div>
      <button type='button' className={`jfButton-invoice ${invoiceMessageType}`} onClick={() => handleCustomNavigation(`${adminConsoleLink}/billing`, '_self')}>
        {t(Texts.VIEW_INVOICE)}
      </button>
    </div>
  );
}

InvoiceWarning.propTypes = {
  adminConsoleLink: string,
  invoice: shape({}),
  invoiceMessageType: string
};

InvoiceWarning.defaultProps = {
  adminConsoleLink: '/admin',
  invoice: {},
  invoiceMessageType: undefined
};
