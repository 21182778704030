import React from 'react';
import { IconCheckCircleFilled } from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';
import { ALL_TEXTS } from '@jotforminc/enterprise-promotions-utils';

const Discounts = () => {
  return (
    <div className='w-full bg-navy-10 flex items-start p-6 radius-xl mb-4 md:mb-6'>
      <IconCheckCircleFilled className="shrink-0 w-6 h-auto mr-2 fill-blue-500" />
      <div className='flex flex-col items-start'>
        <h4 className='mt-0 mb-2 font-medium text-md line-height-xl color-navy-700'>{t(ALL_TEXTS.EDUCATION_AND_NONPROFIT_DISCOUNT)}</h4>
        <p className='m-0 text-xs line-height-xs color-navy-300'>{t(ALL_TEXTS.EDUCATION_AND_NONPROFIT_DISCOUNT_EXPLANATION)}</p>
      </div>
    </div>
  );
};

export default Discounts;
