import { RequestLayer, Interceptors } from '@jotforminc/request-layer';

const layer = new RequestLayer('/API', {
  interceptorConfig: {
    teamID: global.teamID,
    customResponseInterceptors: [Interceptors.requestManagerResponseNormalizer]
  }
});

const serverRequestLayer = new RequestLayer('/server.php/', {
  interceptorConfig: {
    teamID: global.teamID,
    disableResponseNormalizer: true,
    shouldCache: true,
    logCachePayload: {
      projectName: 'myaccount'
    }
  }
});

export const trackAction = data => layer.post('t/jfrev', data, {
  headers: {
    'Content-Type': 'application/json'
  }
});

export const userReferralDetails = () => serverRequestLayer.post('?action=getReferralDetails&source=7');
