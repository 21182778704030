import React, { useEffect, useRef, useState } from 'react';
import { elementType, node, string } from 'prop-types';

import { IconXmark } from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';
import { readCookie, setCookie } from '@jotforminc/utils';

import './flyin.scss';

import { useAssetInfo } from '../../../hooks/useCampaignInfo';

const FlyIn = ({
  isSticky,
  onClose,
  children,
  CloseIconRenderer,
  ...props
}) => {
  const FlyInRef = useRef(null);
  const {
    type: campaignType,
    utm_campaign: utmCampaign,
    assetsVersion
  } = useAssetInfo();

  const isHideCookie = readCookie(`GAM:Flyin:${utmCampaign}:${assetsVersion}:hide`) === 'true';

  const [isShow, setIsShow] = useState(!isHideCookie);

  const handleClose = () => {
    onClose();
    setCookie(`GAM:Flyin:${utmCampaign}:${assetsVersion}:hide`, 'true', 1);
    document.querySelector('body').classList.remove('jf-flyin--isSticky');
    setIsShow(false);
  };

  useEffect(() => {
    if (isHideCookie) return;
    if (!isSticky) {
      document.querySelector('body').classList.add('jf-flyin--isSticky');
      setIsShow(true);
    } else {
      document.querySelector('body').classList.remove('jf-flyin--isSticky');
      setIsShow(false);
    }
  }, [isSticky, isHideCookie]);

  useEffect(() => {
    if (isShow) {
      document.querySelector('body').classList.add('jf-flyin');
    }
  }, [isShow]);

  if (!isShow) {
    return null;
  }

  return (
    <div
      ref={FlyInRef}
      className="jfRHeader--flyin"
      data-campaign-type={campaignType}
      {...props}
    >
      <div className="jfRHeader--flyin-inner">{children}</div>
      <button
        type="button"
        className="jfRHeader--flyin-close-btn"
        aria-label={t('Close')}
        onClick={handleClose}
      >
        <CloseIconRenderer />
      </button>
    </div>
  );
};

FlyIn.propTypes = {
  children: node.isRequired,
  isSticky: string.isRequired,
  CloseIconRenderer: elementType,
  onClose: () => {}
};

FlyIn.defaultProps = {
  onClose: () => {},
  CloseIconRenderer: IconXmark
};

export default FlyIn;
