import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { t, translationRenderer } from '@jotforminc/translation';
import cx from 'classnames';

import { ALMOST_FULL_WARNING, OVERQUOTA_WARNING, UPGRADE_NOW } from '../../constants/texts';
import { actionTracker, bsgUtmCreator, mapLimitType } from '../../utils';
import '../../assets/account-box-limit-warnings.scss';

const AccountBoxLimitWarnings = ({
  user, type, limit
}) => {
  const { username = '', account_type: { name: planType = '' } } = user;
  const assetType = `account-box-${limit}-${type}-warning`;

  useEffect(() => {
    actionTracker({
      username,
      target: assetType,
      action: 'seen'
    });
  }, []);

  const handleUpgradeClicked = () => {
    actionTracker({
      username,
      target: assetType,
      action: 'upgrade-btn-clicked'
    });

    window.open(`/pricing${bsgUtmCreator({ planType, assetType, campaign: limit })}`);
  };

  const quotaBoxClassnames = cx('flex flex-col items-center gap-3 px-5 py-3 radius', {
    'almostFull-box': type === 'almostFull',
    'overquota-box': type === 'overquota'
  });

  const quotaBtnClassnames = cx('flex justify-center items-center radius text-sm z-1 font-bold w-36 my-0 mx-auto py-2', {
    'almostFull-upgradeButton': type === 'almostFull',
    'overquota-upgradeButton': type === 'overquota'
  });

  return (
    <section className='px-5 pt-4 bg-navy-25'>
      <div className={quotaBoxClassnames}>
        <p className='text-sm text-center'>
          {type === 'overquota' && (
            <>
              {translationRenderer(OVERQUOTA_WARNING)({
                renderer1: () => <span className='font-bold'>{t(mapLimitType(limit))}</span>
              })}
            </>
          )}
          {type === 'almostFull' && translationRenderer(ALMOST_FULL_WARNING)({
            renderer1: () => <span className='font-bold'>{t(mapLimitType(limit))}</span>
          })}
        </p>
        <button
          className={quotaBtnClassnames}
          type='button'
          onClick={() => handleUpgradeClicked()}
        >
          {t(UPGRADE_NOW)}
        </button>
      </div>
    </section>
  );
};

AccountBoxLimitWarnings.propTypes = {
  user: PropTypes.object,
  type: PropTypes.string,
  limit: PropTypes.string
};

AccountBoxLimitWarnings.defaultProps = {
  user: {},
  type: '',
  limit: ''
};

export default AccountBoxLimitWarnings;
