import React from 'react';
import './index.scss';

const BlackFridayBanner = ({ ...props }) => {
  if (!document.body.classList.contains('blackfriday-body')) {
    document.body.classList.add('blackfriday-body');
  }

  return (
    <div className="black-friday-banner" {...props}>
      <span>blackfriday</span>
    </div>
  );
};

export default BlackFridayBanner;
