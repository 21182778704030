import { t, translationRenderer } from '@jotforminc/translation';
import { shape, bool } from 'prop-types';
import React from 'react';
import { Texts } from '../../constants';

export const SaveBagde = ({
  campaign, isUKNewUserCampaignActive, isOverquotaCampaignActive, isBtp2023CampaignActive
}) => {
  const computedCampaign = isUKNewUserCampaignActive && !isOverquotaCampaignActive && !isBtp2023CampaignActive ? {
    status: true,
    utm_campaign: 'newUserDesingTest'
  } : campaign;

  const { type: campaignType } = campaign;

  const { status = false, utm_campaign: utmCampaign = '' } = computedCampaign;

  if (!status) return null;

  let className = 'save-badge';
  if (isUKNewUserCampaignActive || campaignType === 'BACKTOPAID') {
    className = `ukNewUserCampaignActive--${className} newUser`;
  }

  if (campaignType === 'SUMMER') {
    className = 'jf-summer-sale-button-upgrade-button-badge';
  }
  return (
    <div className={`${className} ${utmCampaign}`}>
      {(['newUser', 'newUserDesingTest', 'btp2023'].includes(utmCampaign) || isOverquotaCampaignActive || isBtp2023CampaignActive) && t(Texts.SAVE_FIFTY_PERC_NOW)}
      {utmCampaign === 'ss2023'
        && translationRenderer(Texts.SAVE_FIFTY_PERCENTAGE)({
          renderer1: text => (
            <strong className='percent'>
              {text}
            </strong>
          )
        })}
      {utmCampaign === 'bf2022'
        && translationRenderer(Texts.SAVE_FIFTY_PERCENTAGE)({
          renderer1: text => (
            <strong key="bf2022">
              {text}
            </strong>
          )
        })}
      {utmCampaign === 'eoy2022'
        && translationRenderer(Texts.SAVE_FIFTY_PERCENTAGE)({
          renderer1: text => (
            <strong key="eoy">
              {text}
            </strong>
          )
        })}
    </div>
  );
};

SaveBagde.propTypes = {
  campaign: shape().isRequired,
  isUKNewUserCampaignActive: bool,
  isOverquotaCampaignActive: bool,
  isBtp2023CampaignActive: bool
};

SaveBagde.defaultProps = {
  isUKNewUserCampaignActive: false,
  isOverquotaCampaignActive: false,
  isBtp2023CampaignActive: false
};
