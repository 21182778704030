import React from 'react';
import { t } from '@jotforminc/translation';

const NewUserMyaccountBadgeV2 = () => {
  return (
    <div className="sm:ml-auto sm:rtl:ml-0 sm:rtl:mr-auto ">
      <div className="p-0.5 radius-lg ml-1" style={{ background: 'linear-gradient( 264.82deg, #0099ff 1.33%,#ffb629 47.09%, #ff6100 92.85%)' }}>
        <div className="px-1.5 py-1.5 flex justify-center items-center bg-green-500 bg-navy-700 color-white font-medium text-center w-full radius-md line-height-xs text-xs font-medium text-uppercase">
          {t('SAVE 50%')}
        </div>
      </div>
    </div>
  );
};

export default NewUserMyaccountBadgeV2;
