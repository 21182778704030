import React, { useState } from 'react';
import { node, string } from 'prop-types';

import {
  Button, Modal, ModalBody
} from '@jotforminc/magnet';
import { IconXmark } from '@jotforminc/svg-icons';

import './lightbox.scss';

import { useAssetInfo } from '../../../hooks/useCampaignInfo';

const Lightbox = ({
  children,
  ariaLabel,
  onClose
}) => {
  const { type: campaignType } = useAssetInfo();

  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
    onClose();
  };
  return (
    <Modal
      ariaLabel={ariaLabel}
      onClose={handleClose}
      size="large"
      open={open}
      id="gamLightbox"
      data-campaign-type={campaignType}
    >
      <div className="gamLightbox-close">
        <Button
          startIcon={IconXmark}
          colorStyle="secondary"
          rounded
          aria-label="Close Button"
          onClick={handleClose}
          size="small"
        />
      </div>
      <ModalBody>
        <div className="gamLightbox-body">
          {children}
        </div>
      </ModalBody>
    </Modal>
  );
};

Lightbox.propTypes = {
  children: node.isRequired,
  ariaLabel: string.isRequired,
  onClose: () => {}
};

Lightbox.defaultProps = {
  onClose: () => {}
};

export default Lightbox;
