/* eslint-disable complexity */
import React from 'react';
import { object } from 'prop-types';
import cx from 'classnames';
import { t } from '@jotforminc/translation';

import PlanHeader from './PlanHeader';
import PlanContent from './PlanContent';
import PlanCTA from './PlanCTA';

import { CAMPAIGN_TYPES, SOURCES, THEMES } from '../constants';

import { usePricingContext } from '../context/pricingTableContext';

import '../styles/silverone_badge.scss';

const Plan = ({ plan }) => {
  const {
    state: {
      isGuestUser,
      source,
      campaign: { type: campaignType },
      campaignStatus,
      forceDiscount,
      theme,
      layout,
      period,
      disableCallToActions,
      showHIPAABadgeOnHeader,
      clickableColumn
    },
    actionLogger,
    onPlanContainerClicked,
    showBestValueBadgeSelector,
    getShowCampaignPrice
  } = usePricingContext();

  const showBestValueBadge = showBestValueBadgeSelector({ plan });
  const showCampaignPrice = getShowCampaignPrice({ plan });

  const { name: planName } = plan;

  const listClasses = cx('w-full max-w-72 radius-lg', {
    'sm:w-60 max-h-120 overflow-scroll px-2': theme === THEMES.LITE,
    'md:w-52 sm:w-60 xxl:w-68 p-1 mx-1': theme === THEMES.DEFAULT,
    'sm:w-48 p-1.5': (theme === THEMES.BILLING || theme === THEMES.MINI) && !campaignStatus,
    'max-w-full w-full sm:w-48 p-1.5': (theme === THEMES.MINI) && !campaignStatus,
    'xs:w-44 mx-2': (theme === THEMES.BILLING) && campaignStatus,
    'w-1/2 md:w-2/6 p-1.5': (theme === THEMES.MINI),
    'mt-6': !showBestValueBadge && (theme !== THEMES.LITE) && !showHIPAABadgeOnHeader
  });

  const buttonClasses = cx('text-center', {
    'w-full h-full flex flex-col': theme !== THEMES.LITE,
    'cursor-pointer': !disableCallToActions && clickableColumn
  });

  const handlePlanClick = ({ clickPoint, event }) => {
    event.stopPropagation();
    if (disableCallToActions || !clickableColumn) return;

    actionLogger({
      action: 'plan-clicked',
      target: [planName, period, clickPoint, layout].filter(f => f).join('_'),
      project: 'jf-pricing-table'
    });

    onPlanContainerClicked(plan);

    if (document.body.classList.contains('jfModalOpened')) return;
    document.body.classList.add('jfModalOpened');
  };

  const isTopCtaVisible = () => {
    if (disableCallToActions || [THEMES.LITE, THEMES.MINI].includes(theme)) return false;

    if (forceDiscount || campaignStatus) return true;

    return false;
  };

  if (campaignStatus && [CAMPAIGN_TYPES.BLACKFRIDAY, CAMPAIGN_TYPES.EOY].includes(campaignType) && plan.name === 'FREE' && !isGuestUser && SOURCES.PRICING === source) {
    return false;
  }

  const silverColumn = (plan.name === 'SILVER');

  return (
    <>
      <li
        className={listClasses}
        data-testid={`pricing-table-${planName.toLowerCase()}-column`}
      >
        { false && silverColumn && (
          <div className="relative">
            <div className="silverone--badge">
              <div className="pulse flex flex-col justify-center items-center">
                {t('ONLY $1')}
                <p>{t('AT CHECKOUT')}</p>
              </div>
            </div>
          </div>
        )}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div
          className={buttonClasses}
          onClick={event => handlePlanClick({ clickPoint: 'plan-container', event })}
          aria-label={`${plan.name}`}
        >
          <PlanHeader
            plan={plan}
            showCampaignPrice={showCampaignPrice}
          />
          {isTopCtaVisible() && (
          <PlanCTA
            plan={plan}
            type='top'
            showCampaignPrice={showCampaignPrice}
            onClick={event => handlePlanClick({ clickPoint: 'top-cta', event })}
          />
          )}
          <PlanContent
            plan={plan}
          />
          <PlanCTA
            plan={plan}
            type="bottom"
            showCampaignPrice={showCampaignPrice}
            onClick={event => handlePlanClick({ clickPoint: 'bottom-cta', event })}
          />
        </div>
      </li>
    </>
  );
};

Plan.propTypes = {
  plan: object.isRequired
};

export default Plan;
