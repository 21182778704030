export const ACCOUNT_BOX_VIEW_MODE = {
  LOGIN_FLOW: 'loginFlow',
  ACCOUNT_SETTINGS: 'accountSettings'
};

export const LIMIT_KEY_MAP = {
  TOTAL_SUBMISSONS: 'totalSubmissions',
  PAYMENTS: 'payments',
  FORMS: 'formCount',
  SIGNED_DOCUMENTS: 'signedDocuments',
  WORKFLOW_RUNS: 'workflowRuns',
  SUBMISSONS: 'submissions',
  UPLOAD_SPACE: 'uploads',
  FORM_VIEWS: 'views'
};

export const USAGE_KEY_MAP = {
  TOTAL_SUBMISSONS: 'total_submissions',
  PAYMENTS: 'payments',
  FORMS: 'form_count',
  SIGNED_DOCUMENTS: 'signed_documents',
  WORKFLOW_RUNS: 'workflow_runs',
  SUBMISSONS: 'submissions',
  UPLOAD_SPACE: 'uploads',
  FORM_VIEWS: 'views'
};

export const LIMIT_KEY_TO_USAGE_KEY_MAP = {
  [LIMIT_KEY_MAP.TOTAL_SUBMISSONS]: USAGE_KEY_MAP.TOTAL_SUBMISSONS,
  [LIMIT_KEY_MAP.PAYMENTS]: USAGE_KEY_MAP.PAYMENTS,
  [LIMIT_KEY_MAP.FORMS]: USAGE_KEY_MAP.FORMS,
  [LIMIT_KEY_MAP.SIGNED_DOCUMENTS]: USAGE_KEY_MAP.SIGNED_DOCUMENTS,
  [LIMIT_KEY_MAP.WORKFLOW_RUNS]: USAGE_KEY_MAP.WORKFLOW_RUNS,
  [LIMIT_KEY_MAP.SUBMISSONS]: USAGE_KEY_MAP.SUBMISSONS,
  [LIMIT_KEY_MAP.UPLOAD_SPACE]: USAGE_KEY_MAP.UPLOAD_SPACE,
  [LIMIT_KEY_MAP.FORM_VIEWS]: USAGE_KEY_MAP.FORM_VIEWS
};

export const LIMIT_LABEL_MAP = {
  [LIMIT_KEY_MAP.TOTAL_SUBMISSONS]: 'total submissions',
  [LIMIT_KEY_MAP.PAYMENTS]: 'payment submissions',
  [LIMIT_KEY_MAP.FORMS]: 'forms',
  [LIMIT_KEY_MAP.SIGNED_DOCUMENTS]: 'signed documents',
  [LIMIT_KEY_MAP.WORKFLOW_RUNS]: 'workflow runs',
  [LIMIT_KEY_MAP.SUBMISSONS]: 'submissions',
  [LIMIT_KEY_MAP.UPLOAD_SPACE]: 'upload space',
  [LIMIT_KEY_MAP.FORM_VIEWS]: 'form views'
};

export const BAR_COLORS = {
  GREEN: '#12AF47',
  YELLOW: '#F9A400',
  ORANGE: '#FF6100',
  RED: '#DC2626'
};

export const DEFAULT_LANG = 'en-US';

export const UNLIMITED_DEFAULT_VALUE = 10000000;

export const PROJECT_NAME = 'accountBox';

export const INLINE_ALERT_TYPES = {
  ENTERPRISE: 'ENTERPRISE',
  WARNING: 'WARNING',
  DANGER: 'DANGER'
};

export const INLINE_LIMIT_ALERT_TRESHOLD = {
  WARNING: 70,
  DANGER: 100
};
