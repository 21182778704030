/* eslint-disable max-len */

import React, { useMemo, useState, useEffect } from 'react';
import cx from 'classnames';
import { t, translationRenderer } from '@jotforminc/translation';
import { IconCheck, IconQuestionCircle } from '@jotforminc/svg-icons';

import {
  COLOR_THEMES, PLAN_COLUMN_COLORS, PLANS, SIGN_PLAN_COLORS
} from '../../constants';

import pricingTableManager from '../../hocs/pricingTableManager';

import { usePricingTable } from '../../hooks/usePricingTable';

import PlanHeader from '../LightESignPricingWithFeatures/PlanHeader';

import { Popper } from '../../components/ui/Popper';

import '../../styles/feature-table.scss';

const productDetails = {
  Usage: [
    { text: 'Sign documents', feature: 'signedDocuments' },
    { text: '[1[Unlimited]] monthly signed submissions' },
    { text: '[1[Unlimited]] signers per document' },
    { text: '[1[Unlimited]] Sign fields per form' },
    { text: '[1[+700]] Sign Templates' }
  ],
  'E-Sign Features': [
    { text: 'Automatic field detection', tip: 'Scans your uploaded PDFs for fillable fields to speed up the setup process' },
    { text: 'Reusable documents', tip: 'Create a signed document once, and send it for signature multiple times to multiple people' },
    { text: 'Embeddable on website', tip: 'Add e-sign documents to your website with a copy-paste code. Works seamlessly with Squarespace, WordPress, Wix, and other popular website builders.' },
    { text: 'Automated approval flows', tip: 'Add documents and signers to approval flows that automatically forward documents, send emails, and complete other approval tasks' },
    { text: 'Signer delegation', tip: 'Allow your signers to assign their documents to someone else' },
    { text: 'Automatic reminder', tip: 'Automated reminder eSend automated reminder emails that notify signers of an upcoming signature deadlinemails notify your signers of an upcoming signature deadline.' },
    { text: 'Add message to signers', tip: 'Add an individual message for a specific participant, or send a general message to all participants' },
    { text: 'Document expiration date', tip: 'Set an expiration date for your document to disable it after a certain period of time' },
    { text: 'CC recipients', tip: 'Allow signers to CC recipients on update emails, so non-signing individuals can stay up to date on the signing process' },
    { text: 'Add Sign documents to app', tip: 'Add your e-sign documents to a custom mobile app' },
    { text: 'Mobile app creator', tip: 'Create your own mobile app in minutes with Jotform’s no-code drag-and-drop App Builder' }
  ],
  'PDF Related Features': [
    { text: 'Turn submissions into PDF & Audit Trails', tip: 'Automatically convert each completed document into a PDF with an audit trail that tracks the time and location of each step in the signing process' },
    { text: 'Turn PDFs into singable documents', tip: 'Upload existing PDF forms to convert them to e-sign documents automatically' },
    { text: 'Automatic field detection' }
  ],
  Integrations: [
    {
      text: 'Cloud storage',
      subtext: '(e.g. Google Drive, Dropbox, OneDrive)',
      tip: 'Automatically sync e-sign documents to trusted cloud storage providers like Google Drive and Dropbox'
    },
    {
      text: 'Email marketing',
      subtext: '(e.g., Mailchimp, ActiveCampaign)',
      tip: 'Automatically sync signer information to your preferred email marketing platform'
    },
    {
      text: 'Data management',
      subtext: '(e.g., Google Sheets, Airtable, Zapier)',
      tip: 'Automatically sync signed document data to thousands of other platforms, or use Jotform’s data management tools to stay organized and collaborate with your team'
    },
    {
      text: 'Sales and lead management',
      subtext: '(e.g., Salesforce, HubSpot)',
      tip: 'Automatically sync e-sign documents to your preferred CRM platform'
    },
    {
      text: 'Project management',
      subtext: '(e.g., Asana, Trello, monday.com)',
      tip: 'Automatically sync signed documents to your favorite project management apps'
    },
    {
      text: 'Other task management',
      tip: 'Automatically send notifications, share documents, and perform other tasks'
    }
  ],
  'Other Jotform Products & Usage': [
    { text: 'Form', feature: 'formCount' },
    { text: 'Monthly submissions', feature: 'submissions', tip: 'Total number of submissions you can receive or import each month' },
    { text: 'Teams', feature: 'perTeam', tip: 'Create shared workspaces for teams in your organization. Let team members create forms, tables, reports, and apps to work collaboratively online. Set different roles and permissions to stay in control of your data.' },
    { text: 'Available space', feature: 'uploads', tip: 'Amount of space you can use for storing files uploaded through your forms' },
    { text: 'Monthly form views', feature: 'views', tip: 'Total number of times your forms can be viewed per month' },
    { text: 'Total submission storage', feature: 'totalSubmissions', tip: 'Total number of submissions you can receive and store in your account' },
    { text: 'Fields per Form', feature: 'fieldPerForm' },
    // { text: 'Elements Per Workflow', feature: 'elementPerWorkflow' },
    { text: 'Monthly Workflow Runs', feature: 'workflowRuns' },
    { text: 'Monthly payment submissions', feature: 'payments', tip: 'Total number of monthly payments you can receive through your forms, processed by one of our payment integrations' }
  ],
  'Security & Compliance': [
    { text: 'Auto-generated audit trail', tip: 'Track the time and location of each step of the signing process with an audit trail that’s automatically generated once a document is complete' },
    { text: 'Two- factor authentication (2FA)', tip: 'Add an extra layer of security to your account with two-factor authentication (2FA). You’ll access your account with a seamless and reliable two-step verification process each time you log in.' },
    { text: 'Single sign - on (SSO)', tip: 'Allow each employee to access and manage their forms with a single set of login credentials. Jotform Enterprise supports SAML 2.0 and integrates with popular SSO solutions like Microsoft, Google, Okta, Duo, OneLogin, and more.' },
    {
      text: 'GDPR compliance features',
      tip: 'Jotform offers security features to help you comply with General Data Protection Regulation (GDPR) guidelines'
    },
    {
      text: 'HIPAA compliance features',
      feature: 'hipaaCompliance',
      tip: 'Protect sensitive medical information with HIPAA compliance features and a Business Associate Agreement (BAA)',
      plans: [PLANS.ENTERPRISE]
    },
    {
      text: 'CCPA compliance features',
      tip: 'The California Consumer Privacy Act (CCPA) protects California consumers by requiring businesses to handle their online and offline data in a responsible manner. In line with CCPA guidelines, Jotform never sells personal information to third-party organizations.',
      plans: [PLANS.ENTERPRISE]
    },
    {
      text: 'SOC 2 compliance features',
      tip: 'Jotform Enterprise abides by the five SOC 2 Trust Service Principles: security, availability, processing integrity, confidentiality, and privacy',
      plans: [PLANS.ENTERPRISE]
    }
  ],
  'Manage Data': [
    { text: 'Cloud Storage', subtext: '(e.g. Google Drive, Dropbox, OneDrive)', tip: 'Gather data, files, and more through your online forms and send them to cloud storage automatically. Jotform integrates with 10+ popular platforms, including Google Drive, Dropbox, OneDrive, Box, and Egnyte.' },
    { text: 'Manage data with Jotform Tables', tip: 'Jotform Tables makes it easy to manage your form data. Add filters and color-coded labels, split data into multiple tabs, and view your data as a spreadsheet, calendar, or individual cards.' },
    { text: 'Data visualization', tip: 'Turn form submissions into visual reports using Jotform Report Builder. Generate bar graphs, pie charts, and submission grids automatically. Analyze form data to make better decisions for your company.' },
    { text: 'Live reports', tip: 'Turn your form data into live reports that update automatically with each new submission' }
  ],
  Support: [
    { text: '24-7 support team', tip: 'Our customer support team is available 24-7!' },
    { text: 'Help center', tip: 'Visit our help center to access informative user guides and Q&As' },
    {
      text: 'Dedicated Support for Enterprise',
      tip: 'With Jotform Enterprise, you’ll get premium, real-time access to our global support team. We also provide a dedicated customer success team to guide you through using our products and services — just schedule a Zoom meeting to reach out and get instant solutions.',
      plans: [PLANS.ENTERPRISE]
    }
  ]
};

const ColGroupRenderer = () => {
  const {
    visiblePlanNames,
    getPlan
  } = usePricingTable();

  const { afterBestValue, beforeBestValue } = useMemo(
    () => {
      let isBestValueFound = false;
      let beforeBestValue_ = 1;
      let afterBestValue_ = 0;

      for (let i = 0; i < visiblePlanNames.length; i++) {
        const planName = visiblePlanNames[i];
        const { isBestValue } = getPlan(planName);
        if (isBestValue) {
          isBestValueFound = true;
        } else if (isBestValueFound) {
          afterBestValue_++;
        } else {
          beforeBestValue_++;
        }
      }

      return {
        afterBestValue: afterBestValue_,
        beforeBestValue: beforeBestValue_
      };
    },
    [visiblePlanNames]
  );

  return (
    <colgroup>
      <col span={beforeBestValue} />
      <col span={1} className="best-value" />
      <col span={afterBestValue} />
    </colgroup>
  );
};

const FeatureDescription = ({
  // eslint-disable-next-line react/prop-types
  text = '', currentValue, subtext, tip
}) => {
  const [showPopper, setShowPopper] = useState();
  const [referenceElement, setReferenceElement] = useState();

  return (
    <div className='flex items-center gap-2 text-md pr-3 md:pr-4'>
      <div className="flex flex-col gap-1">
        <span className='text-sm lg:text-md'>
          {
            text.includes('[1[')
              ? translationRenderer(text)({
                renderer1: text_ => <strong key={`${currentValue}-${text}-text-strong`}>{text_}</strong>
              })
              : t(text)
          }
        </span>
        {subtext && <span className="text-xs lg:text-sm color-navy-300">{subtext}</span>}
      </div>
      {tip && (
        <div
          className='relative w-5 cursor-pointer flex items-center justify-center'
          ref={setReferenceElement}
          onMouseOver={() => setShowPopper(true)}
          onMouseMove={() => setShowPopper(true)}
          onFocus={() => setShowPopper(true)}
          onMouseLeave={() => setShowPopper(false)}
          onBlur={() => setShowPopper(false)}
        >
          <IconQuestionCircle className='w-5 color-navy-75' />
          {(showPopper && referenceElement) && (
            <Popper
              referenceElement={referenceElement}
              options={{
                placement: 'top'
              }}
            >
              {t(tip)}
            </Popper>
          )}
        </div>
      )}
    </div>
  );
};

// eslint-disable-next-line react/prop-types
const TableHeader = ({ onStickyStateChanged }) => {
  const {
    visiblePlanNames,
    theme,
    getPlan
  } = usePricingTable();

  const [isTHeadSticky, setTHeadSticky] = useState();

  const palette = useMemo(() => {
    return theme === COLOR_THEMES.LIGHT ? PLAN_COLUMN_COLORS.LIGHT : PLAN_COLUMN_COLORS.DEFAULT;
  }, [theme]);

  const stickyHeaderListener = () => {
    const header = document.querySelector('header');
    const table = document.querySelector('table');

    const headerRect = header.getBoundingClientRect();
    const tableRect = table.getBoundingClientRect();
    let isTHeadSticky_ = false;

    if (
      headerRect.bottom > tableRect.top
      && headerRect.top < tableRect.bottom
    ) {
      if (table.getBoundingClientRect().top > headerRect.bottom) {
        isTHeadSticky_ = false;
      } else {
        isTHeadSticky_ = true;
      }
    } else {
      isTHeadSticky_ = false;
    }

    setTHeadSticky(isTHeadSticky_);
    onStickyStateChanged(isTHeadSticky_);
  };

  useEffect(() => {
    global.window?.addEventListener('scroll', stickyHeaderListener);
    return () => global.window?.removeEventListener('scroll', stickyHeaderListener);
  });

  useEffect(() => {
    if (isTHeadSticky) {
      const tableContainerEl = document.querySelector('#standalone-pricing-feature-list .table-container');
      const tableTHeadTrEl = document.querySelector('#standalone-pricing-feature-list > .table-container > table > thead > tr');

      let startX;
      let currentX;

      const swipeTHead = () => {
        const screenWidth = window.innerWidth;
        let offset;
        if (screenWidth === 768) {
          offset = 28;
        } else if (screenWidth < 992) {
          offset = 30;
        } else {
          offset = 32;
        }
        tableTHeadTrEl.style.left = `${offset - tableContainerEl.scrollLeft}px`;
      };

      const swipeTBody = x => {
        tableContainerEl.scrollLeft -= x;
      };

      const saveStartPoint = event => {
        startX = event.touches[0].clientX;
      };

      const calculateTouchDiffAndScrollBody = event => {
        currentX = event.touches[0].clientX;
        const deltaX = currentX - startX;
        startX = currentX;
        swipeTBody(deltaX);
      };

      swipeTHead();

      tableContainerEl.addEventListener('scroll', swipeTHead);
      tableTHeadTrEl.addEventListener('touchstart', saveStartPoint);
      tableTHeadTrEl.addEventListener('touchmove', calculateTouchDiffAndScrollBody);

      return () => {
        tableContainerEl.removeEventListener('scroll', swipeTHead);
        tableTHeadTrEl.removeEventListener('touchstart', saveStartPoint);
        tableTHeadTrEl.removeEventListener('touchmove', calculateTouchDiffAndScrollBody);
      };
    }
  }, [isTHeadSticky]);

  return (
    <thead className={isTHeadSticky ? 'sticky-thead' : ''}>
      <tr>
        <th />
        {
        visiblePlanNames.map(planName => {
          const { isBestValue } = getPlan(planName);
          return (
            <th key={planName} className={isBestValue ? 'best-value-header-cell' : ''}>
              <PlanHeader
                planName={planName}
                palette={palette}
                showCTA={true}
              />
            </th>
          );
        })
      }
      </tr>
    </thead>
  );
};

const LightESignPricingWithProdDetails = () => {
  const {
    visiblePlanNames,
    // isElementPerWorkflowLimitActive,
    isWorkflowRunsLimitActive,
    getPlan
  } = usePricingTable();

  const [isTHeadSticky, setTHeadSticky] = useState();

  const CheckIcon = <IconCheck className='w-6 check-icon' />;

  return (
    <div className="table-container relative">
      <table className='feature-table color-navy-700'>
        <ColGroupRenderer />
        <TableHeader onStickyStateChanged={setTHeadSticky} />
        <tbody className={isTHeadSticky ? 'sticky-thead' : ''}>
          {
            Object
              .keys(productDetails)
              .reduce((previousValue, currentValue) => {
                return [
                  ...previousValue,
                  <tr key={`${currentValue}-group`} className='table-feature-title'>
                    <td>
                      { t(currentValue) }
                    </td>
                    {visiblePlanNames.map(planName => {
                      const { isBestValue } = getPlan(planName);
                      const PLAN_COLORS = isBestValue ? SIGN_PLAN_COLORS.BEST_PLAN : SIGN_PLAN_COLORS[planName];
                      return (
                        <td
                          key={`${currentValue}-${planName}-blank`}
                          style={isBestValue ? undefined : { backgroundColor: PLAN_COLORS.BACKGROUND }}
                        />
                      );
                    })}
                  </tr>,
                  ...productDetails[currentValue]
                    .filter(productDetail => {
                      // if (productDetail.feature === 'elementPerWorkflow') {
                      //   return isElementPerWorkflowLimitActive;
                      // }

                      if (productDetail.feature === 'workflowRuns') {
                        return isWorkflowRunsLimitActive;
                      }

                      return true;
                    })
                    .map(({
                      text,
                      subtext,
                      tip,
                      feature,
                      plans
                    }) => {
                      return (
                        <tr key={`${currentValue}-${text}`}>
                          <td key={`${currentValue}-${text}-text`}>
                            <FeatureDescription
                              text={text}
                              currentValue={currentValue}
                              subtext={subtext}
                              tip={tip}
                            />
                          </td>
                          {
                          visiblePlanNames.map(planName => {
                            const { features, isBestValue } = getPlan(planName);

                            const PLAN_COLORS = isBestValue ? SIGN_PLAN_COLORS.BEST_PLAN : SIGN_PLAN_COLORS[planName];
                            const featureCellClasses = cx(
                              'text-sm lg:text-md relative',
                              {
                                'best-value-feature-cell': isBestValue
                              }
                            );

                            let value = CheckIcon;

                            if (feature) {
                              value = features.find(f => f.feature === feature)?.count;
                            }

                            if (value === true || value === 1) {
                              value = CheckIcon;
                            }

                            return (
                              <td
                                key={`${currentValue}-${text}-${planName}`}
                                style={isBestValue ? undefined : { backgroundColor: PLAN_COLORS.BACKGROUND, zIndex: -1 }}
                                className={featureCellClasses}
                              >
                                {
                                  // eslint-disable-next-line no-nested-ternary
                                  (!plans || (plans && plans.includes(planName)) || feature) && value !== 0
                                    ? (typeof value === 'string' ? t(value) : value)
                                    : ''
                                }
                              </td>
                            );
                          })
                        }
                        </tr>
                      );
                    })
                ];
              }, [])
            }
        </tbody>
      </table>
    </div>
  );
};

export default pricingTableManager(LightESignPricingWithProdDetails);
