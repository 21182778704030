import React, {
  useEffect, useRef, useState
} from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';
import { bool, func } from 'prop-types';
import { t } from '@jotforminc/translation';
import { ABTestManager } from '@jotforminc/abtest-manager';
import { Loading } from '@jotforminc/loading';
import { setCookie } from '@jotforminc/utils';
import { IconRocketFilled } from '@jotforminc/svg-icons';
import {
  createGaAttributes, getRootElement, saveCustomerAsHubspotFormByUsername, setEPActions,
  ASSET_IDENTIFIER, ENTERPRSE_PROMOTIONS_ROOT_EL_ID, SDR_SOURCES, userPropType
} from '@jotforminc/enterprise-promotions-utils';
import { AdminConsoleDarkModal } from '../AdminConsoleDarkModal';
import IconEnterpriseRocket from '../../assets/enterpriseRocket.svg';
import './adminConsolePromotionButton.scss';

const AdminConsolePromotionButton = ({ user, onClick }) => {
  const { PRODUCT: { ADMIN_CONSOLE_PROMOTION_BUTTON: target } } = ASSET_IDENTIFIER;

  const gaAttributes = createGaAttributes(target);

  const logNamingAbTestActionRef = useRef(f => f);
  const modalRoot = getRootElement(ENTERPRSE_PROMOTIONS_ROOT_EL_ID);

  const [isLoading, setIsLoading] = useState(false);
  const [isNamingTestVariant, setIsNamingTestVariant] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    setEPActions({ asset: target, target, action: 'seen' });
  }, []);

  // A/B Test: epAccountBoxAdminConsoleNamingII
  useEffect(() => {
    const distributeAbTest = async () => {
      const { created_at: createdAt } = user;

      const userCreatedDate = new Date(createdAt);
      const startingDate = new Date('2024-09-18');
      startingDate.setHours(0, 0, 0, 0);

      const isNewUserSinceStarting = userCreatedDate >= startingDate;
      if (!isNewUserSinceStarting) return;

      const abTestManager = new ABTestManager({
        isTestEnabled: true,
        testName: 'epAccountBoxAdminConsoleNamingII',
        controlVariantCode: '27981',
        testVariantCode: '27991',
        urlParam: 'epabacnii',
        user,
        cacheVariantCodeAtLocalStorage: true
        // debugMode: {
        // forceTestVariant: true,
        // logTestState: true
        // }
      });

      logNamingAbTestActionRef.current = abTestManager.registerABTestAction;

      try {
        setIsLoading(true);
        const isTestVar = await abTestManager.isTestVariant();
        setIsNamingTestVariant(isTestVar);

        setCookie('abNote', `${isTestVar ? 'epAccountBoxAdminConsoleNamingII-test' : 'epAccountBoxAdminConsoleNamingII-control'}`, 30);
        logNamingAbTestActionRef.current({ action: 'seen', target: isTestVar ? `${target}-epAccountBoxAdminConsoleNamingII` : target });
      } finally {
        setIsLoading(false);
      }
    };

    distributeAbTest();
  }, [user]);

  const handleModalClose = () => {
    ReactDOM.unmountComponentAtNode(modalRoot);
    setIsModalVisible(false);
  };

  const handleAdminConsolePromotionClick = () => {
    setIsModalVisible(true);
    setEPActions({ asset: target, target, action: 'click' });

    logNamingAbTestActionRef.current({ action: 'click', target: isNamingTestVariant ? `${target}-epAccountBoxAdminConsoleNamingII` : target });

    saveCustomerAsHubspotFormByUsername({ lastProductAssetInteraction: SDR_SOURCES.ACCOUNT_BOX_ADMIN_CONSOLE_BUTTON });
    onClick();
  };

  const renderEnterprisePropertyModal = () => {
    ReactDOM.render(
      <AdminConsoleDarkModal
        user={user}
        onClose={handleModalClose}
        isEducationDiscountMode={false}
        logAbTestAction={logNamingAbTestActionRef.current}
        isAdminConsoleNoLabelMode={false}
      />,
      modalRoot
    );
  };

  useEffect(() => {
    if (!isModalVisible) return;
    renderEnterprisePropertyModal();
  }, [isModalVisible]);

  const buttonClasses = cx({
    'jNewHeader-adminConsolePromotion': true,
    educationDiscount: false
  });

  return (
    <button
      {...gaAttributes}
      type="button"
      data-testid="enterpriseAdminConsolePromotionButton"
      className={buttonClasses}
      onClick={handleAdminConsolePromotionClick}
      role="menuitem"
    >
      <span className="locale">{t(isNamingTestVariant ? 'Manage Users & Data' : 'Admin Console')}</span>
      {isLoading ? <div className='loadingWrapper'><Loading /></div> : <IconEnterpriseRocket />}
      {false && (
        <div className='discountRate'>
          <IconRocketFilled />
          {t('30% off')}
        </div>
      )}
    </button>
  );
};

AdminConsolePromotionButton.propTypes = {
  user: userPropType.isRequired,
  onClick: func,
  isEducationDiscountMode: bool
};

AdminConsolePromotionButton.defaultProps = {
  onClick: f => f,
  isEducationDiscountMode: false
};

export default AdminConsolePromotionButton;
