import ReactDOM from 'react-dom';
import { getRootElement } from '@jotforminc/enterprise-promotions-utils';
import { ROOT_EL_ID } from '../utils';

const cleanEnterpriseCheckoutLikeSection = () => {
  const rootEl = getRootElement(ROOT_EL_ID);
  ReactDOM.unmountComponentAtNode(rootEl);
};

export default cleanEnterpriseCheckoutLikeSection;
