/* eslint-disable max-len */
import React, { useState } from 'react';

import { t } from '@jotforminc/translation';

import './v1.scss';

import Modal from '../../../../../ui/Lightbox/Lightbox';

const SilverOneModal = () => {
  const [collapse, setCollapse] = useState(false);
  const toggleDetails = () => {
    setCollapse(!collapse);
  };
  const onClose = () => {
    console.log('action: onClose');
  };

  return (
    <Modal
      onClose={onClose}
      title="SilverOne Lightbox"
    >
      <div className="lightbox-content">
        <div className="lightbox--modal-content">
          <div className="modal-content-top">
            <div className="lightbox--wrapper">
              <div className="center">
                <div className="badge">
                  <div className="badge-inline">{t('LIMITED TIME OFFER')}</div>
                </div>
                <div className="heading">
                  <div className="top-title">{t('Silver Plan')}</div>
                  <div className="title">{t('Only $1')}</div>
                  <div className="bottom-title">
                    {t('FOR A YEAR')}
                    *
                  </div>
                  <div className="subtitle">
                    {t('Get more than double your limits')}
                    {' '}
                    <br />
                    {' '}
                    {t('by upgrading to a Silver plan.')}
                  </div>
                </div>
                <div className="button">
                  <a href="#" className="button-1">
                    Upgrade now
                  </a>
                  {!collapse && (
                    <a href="#" className="button-2" onClick={toggleDetails}>
                      Learn more
                    </a>
                  )}
                </div>
              </div>
              <img
                className="money"
                src="https://cdn.jotfor.ms/assets/img/campaigns/2024/only-one/lightbox/coin.png"
                alt="Coin"
              />
            </div>
          </div>

          {collapse && (
            <>
              <div className="modal-content-table">
                <table className="silver-one-pricing-table" cellSpacing="0" cellPadding="0">
                  <colgroup />
                  <colgroup />
                  <colgroup />
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>
                        <h2>Bronze</h2>
                      </th>
                      <th class="plan-silver">
                        <h2>Silver</h2>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Form Limit</th>
                      <td>25 Forms</td>
                      <td>50 Forms</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <th>Monthly Submissions</th>
                      <td>1,000</td>
                      <td>2,500</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <th>Available Space</th>
                      <td>1 GB</td>
                      <td>10 GB</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <th>Monthly Form Views</th>
                      <td>10,000</td>
                      <td>100,000</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <th>Monthly Signed Documents</th>
                      <td>100</td>
                      <td>250</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <th />
                      <td />
                      <td />
                      <td />
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="modal-content-footer">
                <p className="lightbox-modal--text">
                  {t(
                    '*Offer does not apply to Jotform Enterprise. The $1 offer is a one-time offer for Bronze users upgrading to a Silver plan. Plans will switch to Silver for the remainder of the existing Bronze billing cycle and be charged at the price of an annual Silver plan at the end of the billing cycle. Cannot be combined with other offers.'
                  )}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default SilverOneModal;
