/* eslint-disable max-len */
import React from 'react';
import { string } from 'prop-types';

import { handleCustomNavigation } from '@jotforminc/utils';

import { t, translationRenderer } from '@jotforminc/translation';

import './v1.scss';

import FlyIn from '../../../../../ui/FlyIn/FlyIn';

import { useFlyinUtm } from '../../../../../../hooks/useUtm';

const SalesForceFlyInV1 = ({ isSticky, ...props }) => {
  const isEN = window?.user?.language === 'en-US' || 'en-US';
  const { url } = useFlyinUtm({
    directURL: '/blog/announcing-new-features-for-salesforce-forms-2024/',
    customUtmCampaign: 'microsoft-teams'
  });

  const handleClick = () => {
    handleCustomNavigation(url, '_blank', true);
  };

  return (
    <FlyIn
      CloseIconRenderer={() => <img className="close-flyin" src="https://cdn.jotfor.ms/assets/img/campaigns/2024/teams/flyin/close.svg" alt="Close Icon" />}
      isSticky={isSticky}
      {...props}
    >
      <div className="announcing-salesforce-wrapper" data-jfa="dynamic-prefill">
        <div className="announcing-salesforce-container">
          <div className="image">
            <img src="https://cdn.jotfor.ms/assets/img/campaigns/2024/salesforce-dynamic-prefill/bird.png" alt="Bird" />
          </div>
          <div className="salesforce-content-container">
            <div className="heading">
              <span>
                {translationRenderer('Announcing 5 new features for [1[Salesforce]]')({
                  renderer1: text => (isEN ? <img className="salesforce-logo" src="https://cdn.jotfor.ms/assets/img/campaigns/2024/salesforce-dynamic-prefill/logo.svg" alt="Salesforce" /> : <strong>{text}</strong>)
                })}
              </span>
            </div>
            <button
              className="cta-button action-link locale" type="button" onClick={handleClick}
            >
              {t('Discover now')}
            </button>
          </div>
        </div>
      </div>
    </FlyIn>
  );
};

SalesForceFlyInV1.propTypes = {
  isSticky: string.isRequired
};

export default SalesForceFlyInV1;
