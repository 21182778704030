import React from 'react';
import capitalize from 'lodash/capitalize';

import { t, translationRenderer } from '@jotforminc/translation';
import { useFormatCurrency } from '@jotforminc/currency';
import { formatBytes } from '@jotforminc/utils';
import { IconUsersFilled, IconProductSignMono, IconProductApprovalsMono } from '@jotforminc/svg-icons';

import IconHipaa from '../assets/svg/iconTableHipaa.svg';
import IconHipaaDisabled from '../assets/svg/iconTableHipaaDisabled.svg';
import IconHipaaDisabledLight from '../assets/svg/iconTableHipaaDisabledLight.svg';

import CustomizeIconSvg from '../assets/svg/customizeIcon.svg';

import IconJFBrandingLight from '../assets/svg/iconTableJFBrandingLight.svg';
import IconJFBranding from '../assets/svg/iconTableJFBranding.svg';
import IconJFBrandingDisabledLight from '../assets/svg/iconTableJFBrandingDisabledLight.svg';
import IconJFBrandingDisabled from '../assets/svg/iconTableJFBrandingDisabled.svg';

import {
  ORDERED_PLAN_LIST,
  CAMPAIGN_TYPES,
  COLOR_THEMES,
  PLAN_COLORS,
  PRICE_TYPES,
  PERIODS,
  PLANS
} from '../constants';
import {
  AS_JOTFORM_HAS_GROWN_AND_EVOLVED_OVER_TIME_OUR_PRICING_AND_SUBSCRIPTION_PLANS_HAVE_CHANGED,
  YOU_ARE_SCHEDULED_TO_BE_DOWNGRADED_TO_FREE_ON_EXPIRATION_DATE,
  HIPAA_COMPLIANCE_GRANDFATHERED,
  BILLED_ANNUALLY_AT_PRICE,
  BILLED_BIYEARLY_AT_PRICE,
  HIPAA_COMPLIANCE_SILVER,
  HIPAA_COMPLIANCE_GOLD_ENTERPRISE,
  ALL_FEATURES_INCLUDED,
  BILLED_ANNUALLY,
  BILLED_BIYEARLY,
  GET_THE_OFFER,
  GET_STARTED,
  REACTIVATE,
  DOWNGRADE,
  CURRENT,
  UPGRADE,
  STARTER,
  MONTH,
  FORM_LIMIT,
  MONTHLY_SUBMISSIONS,
  UNLIMITED,
  FORMCOUNT_FORMS,
  PER_TEAM,
  AVAILABLE_SPACE,
  MONTHLY_FORM_VIEWS,
  TOTAL_SUBMISSION_STORAGE,
  FIELDS_PER_FORM,
  MONTHLY_PAYMENT_SUBMISSIONS,
  MONTHLY_SIGNED_DOCUMENTS,
  REPORTS_FIELDS_PER_FORM,
  FORMS_REPORTS_FIELDS_PER_FORM,
  HIPAA_COMPLIANCE_AVAILABLE,
  HIPAA_COMPLIANCE_NOT_AVAILABLE,
  JOTFORM_BRANDING,
  NO_BRANDING,
  ONE_USER,
  GET_A_QUOTE,
  MONTHLY_WORKFLOW_RUNS,
  CUSTOM_BRANDING
} from '../constants/texts';

import { usePricingContext } from '../context/pricingTableContext';

import { comparePlans, isUnlimited } from '../utils';

const getMonthlyPriceForPeriod = (price, period) => {
  switch (period) {
    case PERIODS.MONTHLY:
      return price;
    case PERIODS.YEARLY:
      return price / 12;
    case PERIODS.BIYEARLY:
      return price / 24;
    default:
      return price;
  }
};

export const usePricingTable = () => {
  const {
    state: {
      user,
      plans,
      subscriptionDetails,
      period,
      userPlan,
      isNonprofitEducationUser,
      hideNoticeBox,
      campaignType,
      campaignStatus,
      showHIPAABadgeOnHeader,
      clickableColumn,
      highlightHipaa,
      priceType,
      hipaaGrandfathered,
      showFieldsPerFormLimit,
      visibleFeatures,
      pullUpFeatures,
      customFeatures,
      showAllFeaturesButton,
      layout
    },
    actionLogger,
    onPlanContainerClicked,
    onSeeAllFeaturesClicked,
    getVisiblePlans,
    getColorTheme,
    getUserLanguage,
    showPeriodSwitchToggleSelector,
    showDealCounterSelector,
    showBestValueBadgeSelector,
    isReactivateScheduledDowngradeEnableSelector
  } = usePricingContext();

  const formatCurrency = useFormatCurrency();
  const language = getUserLanguage();
  const theme = getColorTheme();
  const showPeriodSwitchToggle = showPeriodSwitchToggleSelector();

  // TODO: add them to prop list
  const showFeatures = true;

  const isTopCTAVisible = (
    priceType === PRICE_TYPES.NONPROFIT || priceType === PRICE_TYPES.DISCOUNTED || (priceType === PRICE_TYPES.USER && campaignStatus)
  );

  const CustomizeIcon = () => (
    <div
      className='flex items-center justify-center w-8 h-8 radius-full mx-auto border'
      style={{ background: '#5E42E8', borderColor: '#BBADFF', marginTop: '-2px' }}
    >
      <CustomizeIconSvg className='transform w-5 h-auto scale-1' />
    </div>
  );

  const getFullPrice = plan => {
    const planPriceObj = plan.switchPeriodPrices || plan;

    if (priceType === PRICE_TYPES.NONPROFIT) {
      const fullPrice = (planPriceObj.nonSalesPrices || planPriceObj.fullPrices || planPriceObj.prices)[period];
      return getMonthlyPriceForPeriod(fullPrice, period);
    }

    if (priceType === PRICE_TYPES.DISCOUNTED) {
      const fullPrice = (planPriceObj.fullPrices || planPriceObj.prices)[period];
      return getMonthlyPriceForPeriod(fullPrice, period);
    }

    if (isNonprofitEducationUser) {
      const fullPrice = planPriceObj.nonSalesPrices[period];
      return getMonthlyPriceForPeriod(fullPrice, period);
    }

    if (planPriceObj.fullPrices && ((period === PERIODS.YEARLY && plan.campaign_status === 'ON') || (period === PERIODS.BIYEARLY && plan.campaign_status_biyearly === 'ON'))) {
      return planPriceObj.fullPrices[PERIODS.MONTHLY];
    }
  };

  const getPrice = plan => {
    const planPriceObj = plan.switchPeriodPrices || plan;
    let { prices } = planPriceObj;

    // Determine prices based on the price type
    if (priceType === PRICE_TYPES.NONPROFIT && planPriceObj.onSale) {
      prices = planPriceObj.onSale.nonProfit;
    } else if (priceType === PRICE_TYPES.DISCOUNTED && planPriceObj.prices) {
      prices = planPriceObj.onSale.prices;
    }

    const price = prices[period];

    // Check campaign status and user price type
    const isUserPriceType = priceType === PRICE_TYPES.USER;
    const isCampaignOffForPeriod = (period === PERIODS.YEARLY && plan.campaign_status === 'OFF')
      || (period === PERIODS.BIYEARLY && plan.campaign_status_biyearly === 'OFF');

    if (isUserPriceType && !showPeriodSwitchToggle && campaignStatus && isCampaignOffForPeriod) {
      return prices[PERIODS.MONTHLY];
    }

    // Return price based on the period
    switch (period) {
      case PERIODS.MONTHLY:
        return price;
      case PERIODS.YEARLY:
        return price / 12;
      case PERIODS.BIYEARLY:
        return price / 24;
      default:
        return price; // Return the price for the period as default in unexpected cases
    }
  };

  const getPlanNote = plan => {
    if (plan.name === PLANS.ENTERPRISE) {
      return;
    }

    if (plan.name === PLANS.FREE) {
      return `* ${t(ALL_FEATURES_INCLUDED)}`;
    }

    const isYearlyOrBiYearly = period === PERIODS.YEARLY || period === PERIODS.BIYEARLY;
    const isEligibleForDiscount = priceType === PRICE_TYPES.NONPROFIT || priceType === PRICE_TYPES.DISCOUNTED || isNonprofitEducationUser;

    if (isYearlyOrBiYearly) {
      if (isEligibleForDiscount) {
        const billedMessage = period === PERIODS.YEARLY ? BILLED_ANNUALLY_AT_PRICE : BILLED_BIYEARLY_AT_PRICE;
        const multiplier = period === PERIODS.YEARLY ? 12 : 24;
        return t(billedMessage).replace('{price}', formatCurrency({ value: getPrice(plan) * multiplier }));
      }

      if (campaignStatus) {
        const isCampaignOnForPeriod = (period === PERIODS.YEARLY && plan.campaign_status === 'ON')
          || (period === PERIODS.BIYEARLY && plan.campaign_status_biyearly === 'ON');

        if (isCampaignOnForPeriod) {
          const billedMessage = period === PERIODS.YEARLY ? BILLED_ANNUALLY_AT_PRICE : BILLED_BIYEARLY_AT_PRICE;
          const multiplier = period === PERIODS.YEARLY ? 12 : 24;
          return t(billedMessage).replace('{price}', formatCurrency({ value: getPrice(plan) * multiplier }));
        }

        if (showPeriodSwitchToggle) {
          return period === PERIODS.YEARLY ? t(BILLED_ANNUALLY) : t(BILLED_BIYEARLY);
        }
      } else {
        return period === PERIODS.YEARLY ? t(BILLED_ANNUALLY) : t(BILLED_BIYEARLY);
      }
    }
  };

  const getPlanCTAText = plan => {
    const planName = plan.name;
    const isGuestNewUser = userPlan === 'GUEST' && campaignType?.includes(CAMPAIGN_TYPES.NEWUSER) && planName !== PLANS.FREE;
    const isInvalidPlan = ORDERED_PLAN_LIST.indexOf(userPlan) < 0;
    const isReactivationEligible = comparePlans(userPlan, planName) === 0 && isReactivateScheduledDowngradeEnableSelector() && planName !== PLANS.FREE && !campaignStatus;
    const isCurrentPlan = priceType === PRICE_TYPES.USER && !getFullPrice(plan) && comparePlans(userPlan, planName) === 0;
    const isUpgrade = comparePlans(userPlan, planName) <= 0;
    const isDowngrade = comparePlans(userPlan, planName) > 0;
    const isEnterprise = planName === PLANS.ENTERPRISE;

    if (isEnterprise) return t(GET_A_QUOTE);
    if (isGuestNewUser) return t(GET_THE_OFFER);
    if (isInvalidPlan) return t(GET_STARTED);
    if (isReactivationEligible) return t(REACTIVATE);
    if (isCurrentPlan) return t(CURRENT);
    if (isUpgrade) return t(UPGRADE);
    if (isDowngrade) return t(DOWNGRADE);
  };

  const getVisibleOrderedFeatureList = limits => {
    if (!showFeatures) return [];

    let visibleOrderedFeatureList = [
      user.showFormCountStats ? 'formCount' : undefined,
      'submissions',
      'perTeam',
      'uploads',
      'views',
      'totalSubmissions',
      showFieldsPerFormLimit && user.showFormCountStats ? 'fieldPerForm' : undefined,
      'payments',
      limits.signedDocuments ? 'signedDocuments' : undefined,
      // limits.elementPerWorkflow ? 'elementPerWorkflow' : undefined,
      limits.workflowRuns ? 'workflowRuns' : undefined,
      !showFieldsPerFormLimit && user.showFormCountStats ? 'reportsFieldsPerForm' : undefined,
      !showFieldsPerFormLimit && !user.showFormCountStats ? 'formsReportsFieldsPerForm' : undefined,
      ...customFeatures,
      'hipaaCompliance',
      'branding'
    ]
      .filter(f => !!f)
      .filter(f => ((!visibleFeatures?.length ? true : visibleFeatures.includes(f)) || (!customFeatures?.length ? true : customFeatures.includes(f))));

    if (pullUpFeatures?.length) {
      visibleOrderedFeatureList = [...pullUpFeatures, ...visibleOrderedFeatureList.filter(f => !pullUpFeatures.includes(f))];
    }

    return visibleOrderedFeatureList;
  };

  // eslint-disable-next-line complexity
  const getPlanFeatures = plan => {
    const featureDetails = {
      formCount: {
        info: t(FORM_LIMIT),
        count: isUnlimited({ planName: plan.name, value: plan.limits.formCount }) ? t(UNLIMITED) : t(FORMCOUNT_FORMS).replace('{formCount}', plan.limits.formCount.toLocaleString(language))
      },
      submissions: {
        info: t(MONTHLY_SUBMISSIONS),
        count: isUnlimited({ planName: plan.name, value: plan.limits.submissions }) ? t(UNLIMITED) : plan.limits.submissions.toLocaleString(language)
      },
      perTeam: {
        info: t(PER_TEAM),
        count: plan.name === PLANS.ENTERPRISE ? t(UNLIMITED) : t(ONE_USER),
        Icon: pullUpFeatures.includes('perTeam') ? IconUsersFilled : undefined
      },
      uploads: {
        info: t(AVAILABLE_SPACE),
        count: plan.name === PLANS.ENTERPRISE ? t(UNLIMITED) : formatBytes(plan.limits.uploads)
      },
      views: {
        info: t(MONTHLY_FORM_VIEWS),
        count: isUnlimited({ planName: plan.name, value: plan.limits.views }) ? t(UNLIMITED) : plan.limits.views.toLocaleString(language)
      },
      totalSubmissions: {
        info: t(TOTAL_SUBMISSION_STORAGE),
        count: isUnlimited({ planName: plan.name, value: plan.limits.totalSubmissions }) ? t(UNLIMITED) : plan.limits.totalSubmissions.toLocaleString(language)
      },
      fieldPerForm: {
        info: t(FIELDS_PER_FORM),
        count: isUnlimited({ planName: plan.name, value: plan.limits.fieldPerForm }) ? t(UNLIMITED) : plan.limits.fieldPerForm.toLocaleString(language)
      },
      payments: {
        info: t(MONTHLY_PAYMENT_SUBMISSIONS),
        count: plan.limits.payments === 100000 || plan.name === PLANS.ENTERPRISE ? t(UNLIMITED) : plan.limits.payments.toLocaleString(language)
      },
      signedDocuments: {
        info: t(MONTHLY_SIGNED_DOCUMENTS),
        count: isUnlimited({ planName: plan.name, value: plan.limits.signedDocuments }) ? t(UNLIMITED) : plan.limits.signedDocuments.toLocaleString(language),
        Icon: pullUpFeatures.includes('signedDocuments') ? IconProductSignMono : undefined
      },
      // elementPerWorkflow: {
      //   info: t(ELEMENTS_PER_WORKFLOW),
      //   count: isUnlimited({ planName: plan.name, value: plan.limits.elementPerWorkflow }) ? t(UNLIMITED) : plan.limits.elementPerWorkflow.toLocaleString(language),
      //   Icon: pullUpFeatures.includes('elementPerWorkflow') ? IconProductApprovalsMono : undefined
      // },
      workflowRuns: {
        info: t(MONTHLY_WORKFLOW_RUNS),
        count: isUnlimited({ planName: plan.name, value: plan.limits.workflowRuns }) ? t(UNLIMITED) : plan.limits.workflowRuns.toLocaleString(language),
        Icon: pullUpFeatures.includes('workflowRuns') ? IconProductApprovalsMono : undefined
      },
      reportsFieldsPerForm: {
        info: t(REPORTS_FIELDS_PER_FORM),
        count: isUnlimited({ planName: plan.name, value: plan.limits.reportsFieldsPerForm }) ? t(UNLIMITED) : plan.limits.reportsFieldsPerForm.toLocaleString(language)
      },
      formsReportsFieldsPerForm: {
        info: t(FORMS_REPORTS_FIELDS_PER_FORM),
        count: isUnlimited({ planName: plan.name, value: plan.limits.formsReportsFieldsPerForm }) ? t(UNLIMITED) : plan.limits.formsReportsFieldsPerForm.toLocaleString(language)
      },
      hipaaCompliance: {
        info: plan.limits.hipaaCompliance === 'true' || plan.limits.hipaaCompliance === true || plan.name === PLANS.ENTERPRISE ? t(HIPAA_COMPLIANCE_AVAILABLE) : t(HIPAA_COMPLIANCE_NOT_AVAILABLE),
        // eslint-disable-next-line no-nested-ternary,max-len
        Icon: plan.limits.hipaaCompliance === 'true' || plan.limits.hipaaCompliance === true || plan.name === PLANS.ENTERPRISE ? IconHipaa : (theme === COLOR_THEMES.LIGHT ? IconHipaaDisabledLight : IconHipaaDisabled),
        count: plan.limits.hipaaCompliance === 'true' || plan.limits.hipaaCompliance === true || plan.name === PLANS.ENTERPRISE ? 1 : 0
      },
      branding: {
        // eslint-disable-next-line no-nested-ternary
        info: plan.name === PLANS.FREE ? t(JOTFORM_BRANDING) : (plan.name === PLANS.ENTERPRISE ? t(CUSTOM_BRANDING) : t(NO_BRANDING)),
        // eslint-disable-next-line no-nested-ternary,max-len
        Icon: plan.name === PLANS.ENTERPRISE ? CustomizeIcon : (plan.name === PLANS.FREE ? (theme === COLOR_THEMES.LIGHT ? IconJFBrandingLight : IconJFBranding) : (theme === COLOR_THEMES.LIGHT ? IconJFBrandingDisabledLight : IconJFBrandingDisabled)),
        count: plan.name === PLANS.FREE ? 1 : 0
      }
    };
    const visibleOrderedFeatureList = getVisibleOrderedFeatureList(plan.limits);
    return visibleOrderedFeatureList.map(feature => ({
      feature,
      ...(customFeatures.includes(feature) ? { info: t(feature), count: t(UNLIMITED) } : featureDetails[feature]),
      isPulledUp: pullUpFeatures.includes(feature)
    }));
  };

  const getPlan = planName => {
    const plan = plans[planName];
    return {
      name: plan.name,
      prettyName: t(planName === PLANS.FREE ? STARTER : plan.prettyName),
      features: getPlanFeatures(plan),
      fullPrice: getFullPrice(plan),
      price: getPrice(plan),
      pricePeriod: t(MONTH),
      note: getPlanNote(plan),
      isBestValue: showBestValueBadgeSelector({ plan }),
      showDealCounter: showDealCounterSelector({ plan }),
      ctaText: getPlanCTAText(plan),
      downgradeNotice: planName === PLANS.FREE && subscriptionDetails?.expirationInfo?.type === 'downgrade' && subscriptionDetails?.expirationInfo?.remain >= 1
        ? t(YOU_ARE_SCHEDULED_TO_BE_DOWNGRADED_TO_FREE_ON_EXPIRATION_DATE).replace('{expirationDate}', subscriptionDetails?.expirationInfo?.date)
        : undefined,
      colors: PLAN_COLORS.DEFAULT[planName],
      onSeeAllFeaturesClicked: () => {
        actionLogger({
          action: 'see-all-features-clicked',
          target: [planName, period, layout].filter(f => f).join('_'),
          project: 'jf-pricing-table'
        });
        onSeeAllFeaturesClicked();
      },
      onPlanContainerClicked: ({ clickPoint }) => {
        actionLogger({
          action: 'plan-clicked',
          target: [planName, period, clickPoint, layout].filter(f => f).join('_'),
          project: 'jf-pricing-table'
        });
        onPlanContainerClicked(plan);
      }
    };
  };

  const getNoticeMessages = () => {
    const noticeMessages = {};
    const { account_type: { planType: accountPriceType, currentPlanType: accountCurrentPriceType } = {} } = user;

    const showGrandfatherUserNotification = (
      [PLANS.PREMIUM, PLANS.ECONOMY, PLANS.PROFESSIONAL].includes(userPlan)
      || (
        Object.keys(PLANS).filter(plan => ![PLANS.ADMIN, PLANS.FREE, PLANS.STORAGE, PLANS['Sub User']].includes(plan)).includes(userPlan)
        && (accountCurrentPriceType !== accountPriceType) && (accountCurrentPriceType < accountPriceType) && !(userPlan === PLANS.GOLD && accountPriceType === '2024')
      )
    );

    if (highlightHipaa) {
      noticeMessages.hipaaMessage = hipaaGrandfathered
        ? translationRenderer(HIPAA_COMPLIANCE_SILVER)({
          renderer1: text => <strong className="font-bold">{text}</strong>,
          renderer2: text => <strong className="font-bold">{text}</strong>
        })
        : translationRenderer(HIPAA_COMPLIANCE_GOLD_ENTERPRISE)({
          renderer1: text => <strong className="font-bold">{text}</strong>
        });
    }

    if (showGrandfatherUserNotification) {
      noticeMessages.priceGrandfatheredNotification = translationRenderer(AS_JOTFORM_HAS_GROWN_AND_EVOLVED_OVER_TIME_OUR_PRICING_AND_SUBSCRIPTION_PLANS_HAVE_CHANGED)({
        renderer1: text => <strong>{text.replace('{plan}', capitalize(t(userPlan)))}</strong>
      });
    }

    if (hipaaGrandfathered) {
      noticeMessages.hipaaGrandfatheredNotification = t(HIPAA_COMPLIANCE_GRANDFATHERED);
    }

    return noticeMessages;
  };

  return {
    visiblePlanNames: getVisiblePlans(),
    showPeriodSwitchToggle,
    hideNoticeBox,
    campaignType,
    campaignStatus,
    showHIPAABadgeOnHeader,
    clickableColumn,
    theme,
    getPlan,
    noticeMessages: getNoticeMessages(),
    isTopCTAVisible,
    showAllFeaturesButton,
    isBottomCTAVisible: true,
    // isElementPerWorkflowLimitActive: plans.BRONZE.limits.elementPerWorkflow,
    isWorkflowRunsLimitActive: plans.BRONZE.limits.workflowRuns,
    onPlanContainerClicked
  };
};
