import React from 'react';
import ReactDOM from 'react-dom';
import { getRootElement } from '@jotforminc/enterprise-promotions-utils';
import { AccountBoxStandalone } from '@jotforminc/account-box';
import { EnterpriseCheckoutLikeSection } from '../components';
import { ROOT_EL_ID, initTest } from '../utils';

const renderEnterpriseCheckoutLikeSection = async ({
  isSSR = false, user, ...props
}) => {
  const rootEl = getRootElement(ROOT_EL_ID);

  const handleClose = () => {
    if (isSSR) {
      window.location.assign('/pricing');
    } else {
      ReactDOM.unmountComponentAtNode(rootEl);
      window.history.back();
    }
  };

  // A/B Tests: epCheckoutLikeSection{type}Logos
  const testProps = await initTest(user);

  ReactDOM.render(
    <EnterpriseCheckoutLikeSection
      {...props}
      {...testProps}
      AccountBoxRenderer={AccountBoxStandalone}
      onClose={handleClose}
      isSSR={isSSR}
      user={user}
    />,
    rootEl
  );
};

export default renderEnterpriseCheckoutLikeSection;
