import { t } from '@jotforminc/translation';
import {
  number, string
} from 'prop-types';
import React from 'react';

import { LIMIT_KEY_MAP } from '../../constants';
import { getColor } from '../../utils';
import UploadUsageInfo from './UploadUsageInfo';
import UsageInfo from './UsageInfo';

const Limit = ({
  name,
  label,
  usage,
  limit,
  percentage,
  language
}) => {
  const getBarStyle = () => ({ width: `${percentage}%`, backgroundColor: getColor(percentage) });

  return (
    <li
      key={name}
      id={`limit_${name}`}
      className="jNewHeader-userLimitListItem"
    >
      <div className="jNewHeader-userLimitListItem-text">
        <strong className="jNewHeader-limitInfoName" title={t(label)}>{t(label)}</strong>
        {/* non upload space limits */}
        {name !== LIMIT_KEY_MAP.UPLOAD_SPACE && (
          <UsageInfo
            name={name}
            usage={usage}
            limit={limit}
            language={language}
          />
        )}
        {/* upload space limit */}
        {name === LIMIT_KEY_MAP.UPLOAD_SPACE && (
          <UploadUsageInfo
            name={name}
            usage={usage}
            limit={limit}
          />
        )}
      </div>
      <div className="jNewHeader-limitBar" id="jNewHeaderForms">
        <div className="jNewHeader-percentage" style={getBarStyle()} data-testid={`${name}Bar`} />
      </div>
    </li>
  );
};

Limit.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  usage: number.isRequired,
  limit: number.isRequired,
  percentage: number.isRequired,
  language: string.isRequired
};

export default Limit;
