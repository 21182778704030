import React from 'react';
import cx from 'classnames';

import { t } from '@jotforminc/translation';

import { CAMPAIGN_TYPES } from '../constants';
import { LEGACY_PLAN_USER_INFORMATION } from '../constants/texts';

import { usePricingTable } from '../hooks/usePricingTable';

const Notice = () => {
  const { campaignType, campaignStatus, noticeMessages } = usePricingTable();

  // TODO: GAM implementation
  const HIPAAClasses = cx('max-w-md mx-auto text-center text-xl color-gray-400 px-2 font-light', {
    'mt-32': [CAMPAIGN_TYPES.NEWUSER, CAMPAIGN_TYPES.OVERQUOTAUSER, CAMPAIGN_TYPES.BACKTOPAID].includes(campaignType),
    'lg:my-10': ![CAMPAIGN_TYPES.NEWUSER, CAMPAIGN_TYPES.OVERQUOTAUSER, CAMPAIGN_TYPES.BACKTOPAID].includes(campaignType)
  });

  const noticeBoxBgStyles = campaign => {
    const defaultStyle = {
      background: 'linear-gradient(0deg, rgba(46, 105, 255, 0.1), rgba(46, 105, 255, 0.1)), #FFFFFF',
      borderColor: '#2E69FF',
      color: '#57647E',
      maxWidth: '906px'
    };

    if (!campaignStatus) return defaultStyle;

    switch (campaign) {
      case CAMPAIGN_TYPES.BLACKFRIDAY:
        return {
          backgroundColor: '#06141e',
          borderColor: '#454E80',
          color: '#8596B7'
        };
      case CAMPAIGN_TYPES.EOY:
        return {
          background: 'linear-gradient(90deg, #0075E3 0%, #0066C3 100%)',
          color: '#ffffff',
          border: 'none'
        };
      case CAMPAIGN_TYPES.SUMMER:
        return {
          borderColor: 'rgba(11, 21, 81, 0.2)',
          color: '#0b1551'
        };
      default:
        return defaultStyle;
    }
  };

  const noticeBoxTitleStyles = campaign => {
    const defaultStyle = { color: '#2E69FF' };

    if (!campaignStatus) return defaultStyle;

    switch (campaign) {
      case CAMPAIGN_TYPES.BLACKFRIDAY:
        return {
          color: '#FF6037'
        };
      case CAMPAIGN_TYPES.EOY:
        return {
          color: '#fff'
        };
      case CAMPAIGN_TYPES.SUMMER:
        return {
          color: '#ff6038'
        };
      default:
        return defaultStyle;
    }
  };

  return (
    <>
      {noticeMessages.hipaaMessage && (
        <div className={HIPAAClasses}>
          {noticeMessages.hipaaMessage}
        </div>
      )}
      {(noticeMessages.hipaaGrandfatheredNotification || noticeMessages.priceGrandfatheredNotification) && (
        <div id="legacy-plan-notice-box" className='max-w-md mx-auto flex justify-center py-5'>
          <div
            className='flex flex-col w-11/12 pt-4 pr-8 pb-6 pl-12 border radius-lg'
            style={noticeBoxBgStyles(campaignType)}
          >
            <h6 className="font-bold text-md line-height-xl mb-2 text-center" style={noticeBoxTitleStyles(campaignType)}>{t(LEGACY_PLAN_USER_INFORMATION)}</h6>
            <ul className="list-disc space-y-6 line-height-xl text-sm">
              {noticeMessages.priceGrandfatheredNotification && <li>{noticeMessages.priceGrandfatheredNotification}</li>}
              {noticeMessages.hipaaGrandfatheredNotification && <li>{noticeMessages.hipaaGrandfatheredNotification}</li>}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Notice;
