/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Button = ({
  children,
  onClick,
  theme,
  className
}) => {
  const classList = cx(
    'flex justify-center items-center radius text-sm z-1 font-medium h-10 text-sm duration-300 transform hover:opacity-80 shrink-0',
    {
      'px-3 py-2 border border-navy-100 color-teams-default bg-white': theme === '',
      'px-3 py-2 border-0 bg-white': theme === 'ghost',
      'px-3 py-2 border-red-600 bg-red-500 color-white': theme === 'danger',
      'px-3 py-2 border-orange-600 bg-orange-600 color-white': theme === 'warning',
      'px-3 py-2 border-green-600 bg-green-500 color-white': theme === 'success',
      'px-3 py-2 border-blue-600 bg-blue-500 color-white': theme === 'primary',
      'px-3 py-2 border-b border-navy-25 justify-start w-full hover:bg-navy-25': theme === 'dropdown'
    },
    className
  );

  return (
    <button onClick={onClick} type="button" className={classList}>
      {children}
    </button>
  );
};
Button.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.node,
  theme: PropTypes.string,
  className: PropTypes.string
};

Button.defaultProps = {
  children: '',
  onClick: null,
  theme: '',
  className: ''
};
export default Button;
