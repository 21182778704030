import React, { useState } from 'react';
import { t } from '@jotforminc/translation';
import cx from 'classnames';
import { ALL_TEXTS, setEPActions, ASSET_IDENTIFIER } from '@jotforminc/enterprise-promotions-utils';
import { func } from 'prop-types';
import BgCircles from '../../assets/svg/enterpriseCheckoutLikeSection/bg.svg';
import ArrowDown from '../../assets/svg/enterpriseCheckoutLikeSection/arrow-down.svg';
import CheckIcon from '../../assets/svg/enterpriseCheckoutLikeSection/check.svg';

const PlanDetails = ({ logAbTestAction }) => {
  const { AB_TEST: { ENTERPRISE_CHECKOUT_LIKE_SECTION: target } } = ASSET_IDENTIFIER;

  const [isDetailsVisible, setIsDetailsVisible] = useState(false);

  const handleSeePlanDetails = () => {
    setIsDetailsVisible(true);

    const actionData = { target: 'seePlanDetailsButton', action: 'click' };

    logAbTestAction(actionData);
    setEPActions({ asset: target, ...actionData });
  };

  return (
    <div
      className='relative w-full px-5 md:px-10 py-4 md:py-8 overflow-hidden min-h-32 md:min-h-36 mb-6'
      style={{ borderRadius: '16px', background: 'linear-gradient(112deg, #F8F8F8 -2.72%, rgba(248, 248, 248, 0.00) 127.17%)' }}
    >
      <BgCircles className="absolute right-0 -top-8 xs:-top-4 lg:top-0 w-72 lg:w-auto lg:max-w-88 z-1" />
      <div className="z-2">
        <button
          type="button"
          className={cx({
            'see-all-link absolute bottom-7 md:bottom-9 right-6 md:right-6 z-5 duration-300 items-center text-sm line-height-md color-navy-300 cursor-pointer': true,
            hiddenjf: isDetailsVisible,
            flex: !isDetailsVisible
          })}
          onClick={handleSeePlanDetails}
        >
          <ArrowDown className="w-4 h-auto mr-1" />
          <span className=''>{t(ALL_TEXTS.SEE_PLAN_DETAILS)}</span>
        </button>
        <div className="text-3xl md:text-4xl line-height-4xl mb-0.5 font-bold" style={{ color: '#6747FF' }}>{t(ALL_TEXTS.CUSTOM_PRICING)}</div>
        <span className="font-medium text-sm line-height-md color-navy-700">{t(ALL_TEXTS.JOTFORM_ENTERPRISE)}</span>
      </div>
      <ul className={cx({
        'duration-300 flex flex-wrap': true,
        'mt-0 h-0 opacity-0': !isDetailsVisible,
        'mt-12 h-auto opacity-100': isDetailsVisible
      })}
      >
        <li className='flex items-center my-1 w-full sm:w-1/2'>
          <CheckIcon className="w-4 h-auto mr-2" />
          <span className='color-navy-700 text-sm line-height-md'>{t(ALL_TEXTS.UNLIMITED_USAGE)}</span>
        </li>
        <li className='flex items-center my-1 w-full sm:w-1/2'>
          <CheckIcon className="w-4 h-auto mr-2" />
          <span className='color-navy-700 text-sm line-height-md'>{t(ALL_TEXTS.MULTIUSER_PLATFORM)}</span>
        </li>
        <li className='flex items-center my-1 w-full sm:w-1/2'>
          <CheckIcon className="w-4 h-auto mr-2" />
          <span className='color-navy-700 text-sm line-height-md'>{t(ALL_TEXTS.DEDICATED_SUPPORT_LIST)}</span>
        </li>
        <li className='flex items-center my-1 w-full sm:w-1/2'>
          <CheckIcon className="w-4 h-auto mr-2" />
          <span className='color-navy-700 text-sm line-height-md'>{t(ALL_TEXTS.USER_MANAGEMENT_AND_ACCESS_CONTROL)}</span>
        </li>
        <li className='flex items-center my-1 w-full sm:w-1/2'>
          <CheckIcon className="w-4 h-auto mr-2" />
          <span className='color-navy-700 text-sm line-height-md'>{t(ALL_TEXTS.SINGLE_SIGN_ON)}</span>
        </li>
        <li className='flex items-center my-1 w-full sm:w-1/2'>
          <CheckIcon className="w-4 h-auto mr-2" />
          <span className='color-navy-700 text-sm line-height-md'>{t(ALL_TEXTS.SLA_EXPLANATION)}</span>
        </li>
        <li className='flex items-center my-1 w-full sm:w-1/2'>
          <CheckIcon className="w-4 h-auto mr-2" />
          <span className='color-navy-700 text-sm line-height-md'>{t(ALL_TEXTS.LOCAL_DATA_RESIDENCY)}</span>
        </li>
        <li className='flex items-center my-1 w-full sm:w-1/2'>
          <CheckIcon className="w-4 h-auto mr-2" />
          <span className='color-navy-700 text-sm line-height-md'>{t(ALL_TEXTS.PROFESSIONAL_SERVICES)}</span>
        </li>
        <li className='flex items-center my-1 w-full sm:w-1/2'>
          <CheckIcon className="w-4 h-auto mr-2" />
          <span className='color-navy-700 text-sm line-height-md'>{t(ALL_TEXTS.HIPAA_COMPLIANCE_AVAILABLE_SHORT)}</span>
        </li>
        <li className='flex items-center my-1 w-full sm:w-1/2'>
          <CheckIcon className="w-4 h-auto mr-2" />
          <span className='color-navy-700 text-sm line-height-md'>{t(ALL_TEXTS.CUSTOM_BRANDING)}</span>
        </li>
        <li className='flex items-center my-1 w-full sm:w-1/2'>
          <CheckIcon className="w-4 h-auto mr-2" />
          <span className='color-navy-700 text-sm line-height-md'>{t(ALL_TEXTS.CUSTOM_DOMAIN)}</span>
        </li>
      </ul>
    </div>

  );
};

PlanDetails.propTypes = {
  logAbTestAction: func
};

PlanDetails.defaultProps = {
  logAbTestAction: f => f
};

export default PlanDetails;
