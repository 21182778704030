import React, { useEffect } from 'react';
import {
  bool, elementType, func, number, string
} from 'prop-types';
import { handleCustomNavigation } from '@jotforminc/utils';
import { removeLastCharEndsWith } from '../../utils';
import {
  Texts, LIMIT_LABEL_MAP, INLINE_ALERT_TYPES, INLINE_LIMIT_ALERT_TRESHOLD
} from '../../constants';

const InlineLimitAlert = ({
  text,
  limitType,
  utmContent,
  limitPercentage,
  enterpriseMode,
  AlertRenderer,
  onSeen,
  onCtaClick
}) => {
  const { DANGER: DANGER_TRESHOLD } = INLINE_LIMIT_ALERT_TRESHOLD;

  useEffect(() => {
    onSeen();
  }, []);

  const getAlertType = () => {
    if (enterpriseMode) {
      return INLINE_ALERT_TYPES.ENTERPRISE;
    } if (limitPercentage < DANGER_TRESHOLD) {
      return INLINE_ALERT_TYPES.WARNING;
    }
    return INLINE_ALERT_TYPES.DANGER;
  };

  const getText = () => {
    if (enterpriseMode) {
      return Texts.YOU_HAVE_REACHED_YOUR_LIMIT_ENTERPRISE;
    } if (limitPercentage < DANGER_TRESHOLD) {
      return Texts.YOU_HAVE_REACHED_YOUR_LIMIT_WARNING;
    }
    return Texts.YOU_HAVE_REACHED_YOUR_LIMIT_DANGER;
  };

  const handleUpgradeOptionClick = e => {
    e.preventDefault();
    onCtaClick();
    if (enterpriseMode) {
      handleCustomNavigation(`/enterprise/contact-sales/${utmContent}`, '_blank', true);
      return;
    }
    handleCustomNavigation(`/pricing/${utmContent}`, '_blank', true);
  };

  const limitWording = removeLastCharEndsWith(LIMIT_LABEL_MAP[limitType], 's');
  const textWithLimitWording = getText().replace('{limitType}', limitWording);

  return (
    <AlertRenderer
      text={text || textWithLimitWording}
      alertType={getAlertType()}
      onCtaClick={handleUpgradeOptionClick}
    />
  );
};

InlineLimitAlert.propTypes = {
  text: string,
  enterpriseMode: bool,
  limitType: string.isRequired,
  utmContent: string,
  limitPercentage: number.isRequired,
  AlertRenderer: elementType,
  onSeen: func,
  onCtaClick: func
};

InlineLimitAlert.defaultProps = {
  text: '',
  utmContent: '',
  enterpriseMode: false,
  AlertRenderer: null,
  onSeen: f => f,
  onCtaClick: f => f
};

export default InlineLimitAlert;
