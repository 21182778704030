/* eslint-disable max-len */
import { isProduction } from '@jotforminc/utils';
import { trackAction } from '../api';
import { LimitTypes } from '../constants';

export const actionTracker = ({
  username, action, target, project
}) => {
  if (!username) {
    return console.log('[Limit Assets Actions] :: Unable to track, user not found.');
  }

  const log = {
    project: project ?? 'limit-assets',
    oldActions: {
      action: action,
      target: target
    },
    actor: username,
    location: window.location && window.location.href
  };

  if (!isProduction()) {
    return console.log('[Limit Assets Actions] :: ', log);
  }

  trackAction(log);
};

export const bsgUtmCreator = ({ planType, campaign, assetType }) => `?utm_source=limit-assets&utm_medium=banner&utm_content=${planType}-${assetType}&utm_campaign=${campaign}`;

export const freeBuilderUtmCreator = ({
  campaign, formId, assetType, source = 'builder'
}) => `?utm_source=${source}&utm_medium=banner&utm_content=${formId}&utm_campaign=${campaign}&utm_term=${assetType}`;

export const mapLimitType = type => (LimitTypes?.[type] || '').toLowerCase();

export const copyToClipboard = text => {
  window.navigator.clipboard.writeText(text);
};

export const getBaseURI = () => (window.__jfrouter?.BASE_URL ? window.__jfrouter?.BASE_URL : `${window.location.protocol}//${window.location.hostname}`);

export const setLocalStorageWithExpiry = (key, value, ttl = 0) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: ttl === 0 ? undefined : now.getTime() + ttl
  };
  global?.localStorage.setItem(key, JSON.stringify(item));
};
