/* eslint-disable max-len */
import { ABTestManager } from '@jotforminc/abtest-manager';
import { setCookie } from '@jotforminc/utils';
import { getEpUserDetails } from '@jotforminc/enterprise-promotions-utils';

export const ROOT_EL_ID = 'enterprise-promotions-modal-root';

// A/B Tests: epCheckoutLikeSection{type}Logos, it's here only for the a/b test to prevent putting stale data to -util thus prevent excessive build
// if success, we'll move it from here to -utils
export const INDUSTRIES = {
  Education: 'Education',
  Government: 'Government',
  Healthcare: 'Healthcare'
};

export const INDUSTRY_DETAILS = {
  [INDUSTRIES.Education]: {
    title: 'Educational institutions',
    logos: [
      { src: 'https://cdn.jotfor.ms/assets/img/pricing/enterprise/flinders-university.png', alt: 'Flinders University Logo' },
      { src: 'https://cdn.jotfor.ms/assets/img/pricing/enterprise/huron-university.png', alt: 'Huron University Logo' },
      { src: 'https://cdn.jotfor.ms/assets/img/pricing/enterprise/yavapai-college.png', alt: 'Yavapai College Logo' },
      { src: 'https://cdn.jotfor.ms/assets/img/pricing/enterprise/gaoe.png', alt: 'Georgia Association of Educators Logo' },
      { src: 'https://cdn.jotfor.ms/assets/img/pricing/enterprise/university-of-michigan.png', alt: 'University of Michigan Logo' },
      { src: 'https://cdn.jotfor.ms/assets/img/pricing/enterprise/mdcps.png', alt: 'Miami-Dade Country Public Schools Logo' }
    ]
  },
  [INDUSTRIES.Government]: {
    title: 'Government agencies',
    logos: [
      { src: 'https://cdn.jotfor.ms/assets/img/pricing/enterprise/three-rivers.png', alt: 'Three Rivers Park District Logo' },
      { src: 'https://cdn.jotfor.ms/assets/img/pricing/enterprise/chandler-arizona.png', alt: 'Chandler Arizona Logo' },
      { src: 'https://cdn.jotfor.ms/assets/img/pricing/enterprise/county-of-marin.png', alt: 'County of Marin Logo' },
      { src: 'https://cdn.jotfor.ms/assets/img/pricing/enterprise/park-county.png', alt: 'Park County Logo' },
      { src: 'https://cdn.jotfor.ms/assets/img/pricing/enterprise/tppl.png', alt: 'Tinley Park Public Library Logo' },
      { src: 'https://cdn.jotfor.ms/assets/img/pricing/enterprise/mdcps.png', alt: 'Miami-Dade County Public Schools Logo' }
    ]
  },
  [INDUSTRIES.Healthcare]: {
    title: 'Healthcare providers',
    logos: [
      { src: 'https://cdn.jotfor.ms/assets/img/pricing/enterprise/healthone.png', alt: 'HealthONE Logo' },
      { src: 'https://cdn.jotfor.ms/assets/img/pricing/enterprise/aps.png', alt: 'Australian Psychological Society Logo' },
      { src: 'https://cdn.jotfor.ms/assets/img/pricing/enterprise/aspen-valley.png', alt: 'Aspen Valley Hospital Logo' },
      { src: 'https://cdn.jotfor.ms/assets/img/pricing/enterprise/denver-health.png', alt: 'Denver Health Logo' },
      { src: 'https://cdn.jotfor.ms/assets/img/pricing/enterprise/one-one-health.png', alt: 'One to One Health Logo' },
      { src: 'https://cdn.jotfor.ms/assets/img/pricing/enterprise/body-brave.png', alt: 'Body Brave Logo' }
    ]
  }
};

export const LOGOS_TEST_CONFIG = {
  [INDUSTRIES.Education]: {
    testName: 'epCheckoutLikeSectionEducationLogos',
    controlVariantCode: '28012',
    testVariantCode: '28022',
    urlParam: 'epclsel'
  },
  [INDUSTRIES.Government]: {
    testName: 'epCheckoutLikeSectionGovernmentLogos',
    controlVariantCode: '28032',
    testVariantCode: '28042',
    urlParam: 'epclsgl'
  },
  [INDUSTRIES.Healthcare]: {
    testName: 'epCheckoutLikeSectionHealthcareLogos',
    controlVariantCode: '28052',
    testVariantCode: '28062',
    urlParam: 'epclshl'
  }
};

export const distributeTest = async (testProps, user) => {
  const abTestManager = new ABTestManager({
    isTestEnabled: true,
    ...testProps,
    user,
    cacheVariantCodeAtLocalStorage: true
    // debugMode: {
    //   forceUserEligible: true,
    //   logTestState: true,
    //   forceTestVariant: true
    // }
  });

  try {
    const testVariant = await abTestManager.isTestVariant();
    return { testVariant, logAbTestAction: abTestManager.registerABTestAction };
  } catch (error) {
    console.log(error);
    return { testVariant: false, logAbTestAction: f => f };
  }
};

const getTestParamFromUrl = () => {
  const params = new URLSearchParams(document.location.search);
  return Object.entries(LOGOS_TEST_CONFIG).find(([, { urlParam }]) => params.has(urlParam))?.[0] || null;
};

export const initTest = async user => {
  const testParam = getTestParamFromUrl();

  if (testParam) {
    return { testVariant: testParam, logAbTestAction: f => f };
  }

  try {
    const { derivedIndustry } = await getEpUserDetails();
    if (!INDUSTRIES[derivedIndustry]) {
      throw new Error('Industry not found.');
    }

    const { testVariant, logAbTestAction } = await distributeTest(LOGOS_TEST_CONFIG[derivedIndustry], user);

    const abNote = `${LOGOS_TEST_CONFIG[derivedIndustry].testName}-${testVariant ? 'test' : 'control'}`;
    setCookie('abNote', abNote, 30);

    return { testVariant: testVariant ? derivedIndustry : false, logAbTestAction };
  } catch (error) {
    return { testVariant: false, logAbTestAction: f => f };
  }
};
