import React, { useEffect } from 'react';
import { t } from '@jotforminc/translation';

import cx from 'classnames';
import {
  bool, func, oneOfType, string
} from 'prop-types';
import {
  ALL_TEXTS, ASSET_IDENTIFIER, setEPActions
} from '@jotforminc/enterprise-promotions-utils';
import Sydney from '../../assets/svg/enterpriseCheckoutLikeSection/logos/sydney.svg';
import RedBull from '../../assets/svg/enterpriseCheckoutLikeSection/logos/red-bull.svg';
import Ford from '../../assets/svg/enterpriseCheckoutLikeSection/logos/ford.svg';
import Netflix from '../../assets/svg/enterpriseCheckoutLikeSection/logos/netflix.svg';
import Armani from '../../assets/svg/enterpriseCheckoutLikeSection/logos/armani.svg';
import Adobe from '../../assets/svg/enterpriseCheckoutLikeSection/logos/adobe.svg';
import { INDUSTRY_DETAILS } from '../../utils';

const LeadingCompanies = ({ logAbTestAction, testVariant }) => {
  const { AB_TEST: { ENTERPRISE_CHECKOUT_LIKE_SECTION: target } } = ASSET_IDENTIFIER;

  useEffect(() => {
    const actionData = { target: 'leadingCompanies', action: 'seen' };

    logAbTestAction(actionData);
    setEPActions({ asset: target, ...actionData });
  }, []);

  const DEFAULT_LOGO_MAP = [
    { Logo: Sydney, key: 'Sydney Logo' },
    { Logo: RedBull, key: 'Redbull Logo' },
    { Logo: Ford, key: 'Ford Logo' },
    { Logo: Netflix, key: 'Netflix Logo' },
    { Logo: Armani, key: 'Armani Logo' },
    { Logo: Adobe, key: 'Adobe Logo' }
  ];

  const logoCx = cx('h-11 w-auto');
  const variantLogos = testVariant ? INDUSTRY_DETAILS[testVariant].logos.map(({ src, alt }) => (
    <img
      src={src} alt={alt} className={logoCx}
      key={alt}
    />
  )) : DEFAULT_LOGO_MAP.map(({ Logo, key }) => <Logo className={logoCx} key={key} />);

  return (
    <div
      className='w-full flex flex-col sm:flex-row md:flex-col items-center sm:px-4 md:px-0 py-8 sm:py-4 md:py-8 radius-xl mb-4'
      style={{ background: 'radial-gradient(163.59% 141.37% at 100% 99.93%, #EDF8FF 0%, #F9F9FF 100%)' }}
    >
      <div
        className='flex flex-col items-center sm:items-start md:items-center justify-center px-4 pb-6 sm:pb-0 md:pb-6 mb-6 sm:mb-0 md:mb-6 border-b sm:border-b-0 md:border-b border-navy-100'
      >
        <p className='m-0 text-md line-height-xl font-medium color-navy-300'>{t(ALL_TEXTS.TRUSTED_PLUS_TEXT)}</p>
        <h4 className='m-0 text-2xl line-height-3xl font-bold color-navy-700'>{t(testVariant ? INDUSTRY_DETAILS[testVariant].title : ALL_TEXTS.LEADING_COMPANIES)}</h4>
      </div>
      <div className='flex flex-wrap justify-center items-center max-w-88 md:max-w-auto sm:ml-auto md:ml-0'>
        {variantLogos}
      </div>
    </div>

  );
};

LeadingCompanies.propTypes = {
  logAbTestAction: func,
  testVariant: oneOfType([string, bool])

};

LeadingCompanies.defaultProps = {
  logAbTestAction: f => f,
  testVariant: false
};

export default LeadingCompanies;
