import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { PricingTableSkeleton } from '@jotforminc/pricing-table';
import { GrowthAssetManager } from '@jotforminc/growth-asset-manager';
import { getUrlParameter } from '@jotforminc/utils';

import '../styles/jfLimitDialog.scss';
import '../styles/DialogShared.scss';

import { BSG_UTM, ENTERPRISE_UTM } from '../constants';
import { LimitDialogDefaultDescriptionRenderer, LimitDialogDefaultTitleRenderer } from './LimitRenderers';
import { prettyAccountName, actionTracker } from '../utils';
import { BlackFridayPromotionRenderer } from '../campaignPromotions/blackFridayPromotion';
import { EoyPromotionRenderer } from '../campaignPromotions/eoyPromotion';
import { LockdownPromotionRenderer } from '../campaignPromotions/lockdownPromotion';
import { SummerPromotionRenderer } from '../campaignPromotions/ssPromotion';
import LimitDialogV2 from './LimitDialogV2';

const LazyPricingTable = React.lazy(/* webpackChunkName: "LazyPricingTable" */ () => import('./PricingTable/PricingTableLazy'));

const LimitDialog = ({
  campaignData,
  currentAccountType,
  activeCampaign,
  limitDialogV2,
  selectedLimitType,
  selectedLimit,
  user,
  availablePlansForUser,
  dispatchedFrom,
  plans
}) => {
  const campaignAvailable = activeCampaign && currentAccountType !== 'GUEST';
  let modalWrapperClass = '';

  const userForPricingTable = {
    ...user,
    showFormCountStats: true
  };

  const gamEnabled = getUrlParameter('gamEnabled') === '1';

  const getPromotion = campaignName => {
    switch (campaignName) {
      case 'OVERQUOTAUSER':
      case 'NEWUSER':
        modalWrapperClass = ' jfLimitDialog-fdWrapper';
        const isNewVersion = campaignData?.assetsVersion === 'v2' || campaignName === 'OVERQUOTAUSER';
        return () => (
          <div className="jfLimitDialog-campaign-promotion">
            <img
              style={{ maxWidth: '100%' }}
              aria-label="%50 Sale Campaign Image"
              src={`https://cdn.jotfor.ms/assets/img/campaigns/${isNewVersion ? '2023' : '2020'}/new-user/myforms/formcount.png?v=0.1`}
            />
          </div>
        );
      case 'BLACKFRIDAY':
        modalWrapperClass = ' jfLimitDialog-bfWrapper';
        return BlackFridayPromotionRenderer;
      case 'EOY':
        modalWrapperClass = ' jfLimitDialog-eoyWrapper';
        return EoyPromotionRenderer;
      case 'LOCKDOWN':
        modalWrapperClass = ' jfLimitDialog-ldWrapper';
        return LockdownPromotionRenderer;
      case 'SUMMER':
        modalWrapperClass = ' jfLimitDialog-ssWrapper';
        return SummerPromotionRenderer;
      default:
        return null;
    }
  };

  const CampaignPromotion = campaignAvailable ? getPromotion(activeCampaign) : null;

  const handlePlanBoxClick = plan => {
    const accountType = prettyAccountName(currentAccountType);

    actionTracker({
      username: user?.username,
      action: `${plan?.name}-${activeCampaign ? `${activeCampaign}-CAMPAIGN-` : ''}clicked`,
      target: dispatchedFrom,
      isV2: limitDialogV2
    });

    window.open(`/pricing/${plan?.name ? plan?.name.toLowerCase() : ''}${BSG_UTM(accountType, dispatchedFrom, selectedLimitType)}&plan=${plan?.prettyName}`, '_blank');
  };

  const handleEnterprisePlanBoxClick = () => {
    actionTracker({
      username: user?.username,
      action: 'ENTERPRISE-clicked',
      target: dispatchedFrom,
      isV2: limitDialogV2
    });
  };

  const upgradeCtaTextClick = () => {
    actionTracker({
      username: user?.username,
      action: `${currentAccountType === 'GOLD' ? 'contact-sales-text' : 'upgrade-now-text'}`,
      target: dispatchedFrom,
      isV2: limitDialogV2
    });
  };

  const welcomeCtaButtonClick = () => {
    actionTracker({
      username: user?.username,
      action: 'todays-only-save-btn',
      target: dispatchedFrom,
      isV2: limitDialogV2
    });
  };

  useEffect(() => {
    actionTracker({
      username: user?.username,
      action: 'seen',
      target: dispatchedFrom,
      isV2: limitDialogV2
    });
  }, []);

  if (limitDialogV2) {
    return (
      <>
        <section className={`limit-dialog-v2 ${activeCampaign === 'NEWUSER' ? 'isCampaign' : ''}`}>
          <LimitDialogV2
            limitType={selectedLimitType}
            limit={selectedLimit}
            activeCampaign={activeCampaign}
            planType={currentAccountType}
            trackCta={upgradeCtaTextClick}
            dispatchedFrom={dispatchedFrom}
            trackWelcomeCta={welcomeCtaButtonClick}
          />
          <React.Suspense fallback={<PricingTableSkeleton availablePlansForUser={availablePlansForUser} />}>
            <div className='price-table-container' style={{ zIndex: 2 }}>
              <LazyPricingTable
                plans={plans}
                user={userForPricingTable}
                visibleFeatures={[selectedLimitType]}
                onPlanContainerClicked={plan => handlePlanBoxClick(plan)}
                source='billing'
                onEnterpriseColumnClicked={handleEnterprisePlanBoxClick}
                customEnterpriseUtmParams={ENTERPRISE_UTM()}
                visiblePlans={availablePlansForUser}
                theme='mini'
                hideNoticeBox={true}
                hideNoBranding={true}
                showFormLimit={true}
                hidePeriodSwitch={true}
              />
            </div>
          </React.Suspense>
        </section>
      </>
    );
  }

  return (
    <div className={`jfBasicModal-body${campaignAvailable ? modalWrapperClass : ''}`}>
      <div className="jfLimitDialog-heading">
        <div className={`jfLimitDialog-icon ${selectedLimit >= 100 ? 'icon-small' : ''}`}>
          {selectedLimit}
        </div>
        <LimitDialogDefaultTitleRenderer limitType={selectedLimitType} />
        <LimitDialogDefaultDescriptionRenderer
          limit={selectedLimit}
          accountType={prettyAccountName(currentAccountType)}
          dispachedFrom={dispatchedFrom}
          limitType={selectedLimitType}
          trackCta={upgradeCtaTextClick}
        />
      </div>
      {campaignAvailable && !gamEnabled && (
        <CampaignPromotion />
      )}
      {gamEnabled && (
        <GrowthAssetManager assetType="limit-dialog" />
      )}
      <React.Suspense fallback={<PricingTableSkeleton availablePlansForUser={availablePlansForUser} />}>
        <div className='price-table-container' style={{ zIndex: 2 }}>
          <LazyPricingTable
            plans={plans}
            user={userForPricingTable}
            visibleFeatures={[selectedLimitType]}
            onPlanContainerClicked={plan => handlePlanBoxClick(plan)}
            source='billing'
            onEnterpriseColumnClicked={handleEnterprisePlanBoxClick}
            customEnterpriseUtmParams={ENTERPRISE_UTM()}
            visiblePlans={availablePlansForUser}
            theme='mini'
            hideNoticeBox={true}
            hideNoBranding={true}
            showFormLimit={true}
            hidePeriodSwitch={true}
          />
        </div>
      </React.Suspense>
    </div>
  );
};

LimitDialog.propTypes = {
  currentAccountType: PropTypes.string,
  plans: PropTypes.object,
  campaignData: PropTypes.object,
  activeCampaign: PropTypes.string,
  selectedLimitType: PropTypes.string,
  selectedLimit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  user: PropTypes.object,
  availablePlansForUser: PropTypes.array,
  dispatchedFrom: PropTypes.string,
  limitDialogV2: PropTypes.bool
};

LimitDialog.defaultProps = {
  currentAccountType: 'FREE',
  plans: {},
  campaignData: {},
  activeCampaign: '',
  selectedLimitType: 'formCount',
  selectedLimit: 5,
  user: {},
  availablePlansForUser: ['BRONZE', 'SILVER', 'GOLD'],
  dispatchedFrom: '',
  limitDialogV2: false
};

export default LimitDialog;
