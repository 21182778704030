import React, { useEffect, useState } from 'react';
import {
  bool, elementType, func, oneOfType, string
} from 'prop-types';
import {
  ASSET_IDENTIFIER, CHECKOUT_LIKE_SECTION_STEPS, userPropType, SDR_SOURCES,
  createGaAttributes, setEPActions, saveCustomerAsHubspotFormByUsername
} from '@jotforminc/enterprise-promotions-utils';
import FormStep from './FormStep';
import Header from './Header';
import ThankYouStep from './ThankYouStep';
import '@jotforminc/jotform.css';

const EnterpriseCheckoutLikeSection = ({
  user,
  AccountBoxRenderer,
  onClose,
  isSSR,
  testVariant,
  logAbTestAction
}) => {
  const [currentStep, setCurrentStep] = useState(CHECKOUT_LIKE_SECTION_STEPS.FORM_STEP);

  const { AB_TEST: { ENTERPRISE_CHECKOUT_LIKE_SECTION: target } } = ASSET_IDENTIFIER;

  const gaAttributes = createGaAttributes(target);

  useEffect(() => {
    logAbTestAction({ target, action: 'seen' });
    setEPActions({ asset: target, target, action: 'seen' });

    saveCustomerAsHubspotFormByUsername({ lastProductAssetInteraction: SDR_SOURCES.PAGE_VIEW_PRICING_ENTERPRISE });

    return () => {
      logAbTestAction({ target, action: 'close' });
      setEPActions({ asset: target, target, action: 'close' });
    };
  }, [user]);

  const handleFormSubmit = () => {
    setCurrentStep(CHECKOUT_LIKE_SECTION_STEPS.THANK_YOU_STEP);
  };

  return (
    <div {...gaAttributes} className="fixed top-0 right-0 bottom-0 left-0 bg-white" style={{ zIndex: 10001 }}>
      <div className="flex flex-col w-full max-h-100vh overflow-auto md:pb-24">
        <Header
          user={user}
          AccountBoxRenderer={AccountBoxRenderer}
          onClose={onClose}
          isSSR={isSSR}
        />
        <div className='relative z-1 flex w-full flex-1 bg-white mt-14 md:mt-18'>
          {/* form step */}
          {currentStep === CHECKOUT_LIKE_SECTION_STEPS.FORM_STEP && (
            <FormStep
              user={user}
              onFormSubmit={handleFormSubmit}
              logAbTestAction={logAbTestAction}
              testVariant={testVariant}
            />
          )}
          {/* thank you step */}
          {currentStep === CHECKOUT_LIKE_SECTION_STEPS.THANK_YOU_STEP && <ThankYouStep onClose={onClose} />}
        </div>
        {/* styles */}
        <style>
          {
            `
            body {
              padding: 0!important
            }
            .see-all-link:hover {
              color: #7456ff
            }
            .see-all-link:hover > svg path{
              fill: #7456ff;
            }
            @media screen and (min-width: 452px) {
              .iframe-wrapper {
                width: calc(100% + 60px);
                margin-left: -30px
              }
            }
            .iframe-height {
              min-height: 628px
            }
            @media screen and (min-width: 375px) {
              .iframe-height {
                min-height: 588px
              }
            }
            @media screen and (min-width: 440px) {
              .iframe-height {
                min-height: 548px
              }
            }
            .max-w-832 {
              max-width: 832px;
            }
            `
          }
        </style>
      </div>
    </div>
  );
};

EnterpriseCheckoutLikeSection.propTypes = {
  user: userPropType.isRequired,
  AccountBoxRenderer: elementType,
  onClose: func,
  isSSR: bool,
  testVariant: oneOfType([string, bool]),
  logAbTestAction: func
};

EnterpriseCheckoutLikeSection.defaultProps = {
  AccountBoxRenderer: null,
  onClose: func,
  isSSR: false,
  testVariant: false,
  logAbTestAction: f => f
};

export default EnterpriseCheckoutLikeSection;
