/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { string } from 'prop-types';

import { handleCustomNavigation } from '@jotforminc/utils';

import { t, translationRenderer } from '@jotforminc/translation';

import './v1.scss';

import FlyIn from '../../../../../ui/FlyIn/FlyIn';

import CountDown from '../../../../../ui/CountDown/CountDown';

import { useAssetInfo } from '../../../../../../hooks/useCampaignInfo';
import { useFlyinUtm } from '../../../../../../hooks/useUtm';

const BackToPaidFlyInV1 = ({
  isSticky, expires, ...props
}) => {
  const { url } = useFlyinUtm({
    directURL: '/pricing'
  });

  const handleClick = () => {
    handleCustomNavigation(url, '_blank', true);
  };

  const {
    expires: {
      back_to_paid_campaign: expirationDate
    }
  } = useAssetInfo();

  return (
    <FlyIn
      isSticky={isSticky}
      {...props}
    >
      <div className="jf-return-to-paid-wrapper" data-jfa="jf-return-to-paid">
        <div className="jf-return-to-paid-wrapper-container">
          <div className="jf-return-to-paid-image">
            <img src="https://cdn.jotfor.ms/assets/img/campaigns/2023/return-to-paid/stripes.svg" alt="Stripes" />
          </div>
          <div className="jf-return-to-paid-title">
            <div className="jf-return-to-paid-title ">
              <span>
                {translationRenderer('[1[TODAY ONLY]] [2[SAVE %50]]')({
                  renderer1: today => <strong>{today}</strong>,
                  renderer2: save => <strong>{save}</strong>
                })}
              </span>
            </div>
          </div>
          <div className="jf-return-to-paid-count-down">
            <span className="jf-return-to-paid-count-down-title ">
              {t('Offer expires in')}
            </span>
            <strong className="jf-return-to-paid-count-down-time">
              <span className="flyin-count-time">
                <CountDown expires={expires ? expires : expirationDate} />
              </span>
            </strong>
          </div>
          <button
            className="jf-return-to-paid-save-now-button locale" type="button" onClick={handleClick}
          >
            {t('Save Now')}
          </button>
        </div>
      </div>
    </FlyIn>
  );
};

BackToPaidFlyInV1.propTypes = {
  isSticky: string.isRequired,
  expires: string
};

BackToPaidFlyInV1.defaultProps = {
  expires: null
};

export default BackToPaidFlyInV1;
