import React, { useEffect, useRef } from 'react';
import { Modal } from '@jotforminc/uikit';
import cx from 'classnames';
import {
  bool, func, object, string
} from 'prop-types';
import { t, translationRenderer } from '@jotforminc/translation';
import {
  PrefilledLeadFormAside, ASSET_IDENTIFIER,
  ALL_TEXTS, GA_FORM_TRACKING_ITEMS, userPropType, GA_AD_FORM_TRACKING_ITEMS,
  GA_SUFFIXES, createGaAttributes, gaDataLayerPushClose, getDevFormID, getLiveFormID, hasAdwordsModeOn, mapCallbacksToGaTrackingItems, setEPActions
} from '@jotforminc/enterprise-promotions-utils';
import {
  ContentRenderer, DialogRenderer, EducationDiscountRenderer, HeaderRenderer
} from './Renderers';
import './adminConsoleDarkModal.scss';
import Graph from '../../assets/graph.svg';
import EnterpriseIcon from '../../assets/enterpriseRocketMini.svg';

const AdminConsoleDarkModal = (({
  user,
  modalProps,
  onCloseClick,
  logAbTestAction,
  isEducationDiscountMode,
  isAdminConsoleNoLabelMode,
  onClose
}) => {
  const uikitModalRef = useRef(null);

  const { PRODUCT: { ADMIN_CONSOLE_DARK_MODAL } } = ASSET_IDENTIFIER;
  const target = ADMIN_CONSOLE_DARK_MODAL;

  const isAdForm = hasAdwordsModeOn();
  let { [target]: gaFormTrackingItems } = isAdForm ? GA_AD_FORM_TRACKING_ITEMS : GA_FORM_TRACKING_ITEMS;

  const gaAttributes = createGaAttributes(target);
  const gaAttributesClose = createGaAttributes(target, GA_SUFFIXES.CLOSE);

  useEffect(() => {
    const actionData = { action: 'seen', target };
    logAbTestAction(actionData);
    setEPActions({ asset: target, target, action: 'seen' });
  }, []);

  const handleCloseClick = () => {
    const actionData = { action: 'click', target: `closeButton-${target}` };
    uikitModalRef.current?.hide();
    onCloseClick();
    logAbTestAction(actionData);
    setEPActions({ asset: target, target: 'closeButton', action: 'click' });
  };

  const handleClose = () => {
    const actionData = { action: 'close', target };
    gaDataLayerPushClose(gaAttributes);
    logAbTestAction(actionData);
    setEPActions({ asset: target, target, action: 'close' });
    onClose();
  };

  const handleContactSalesClick = () => {
    const actionData = { action: 'click', target: `contactSalesButton-${target}` };
    logAbTestAction(actionData);
    setEPActions({ asset: target, target: 'contactSalesButton', action: 'click' });
  };

  const handleLearnMoreClick = () => {
    const actionData = { action: 'click', target: `learnMoreButton-${target}` };
    logAbTestAction(actionData);
    setEPActions({ asset: target, target: 'learnMoreButton', action: 'click' });
  };

  const handleFormSubmit = () => {
    const actionData = { action: 'formSubmit', target };
    logAbTestAction(actionData);
    setEPActions({ asset: target, target, action: 'formSubmit' });
  };

  gaFormTrackingItems = mapCallbacksToGaTrackingItems(gaFormTrackingItems, [handleContactSalesClick, handleLearnMoreClick]);

  return (
    <Modal
      ref={uikitModalRef}
      defaultVisible={true}
      closeOnEscPress={true}
      closeOnOutsideClick={false}
      DialogRenderer={DialogRenderer}
      ContentRenderer={props => <ContentRenderer {...gaAttributes} {...props} />}
      HeaderRenderer={() => <HeaderRenderer {...gaAttributesClose} onCloseClick={handleCloseClick} />}
      onModalClose={handleClose}
      {...modalProps}
    >
      <div className="row">
        <div className={cx('left-col direct-visible', {
          'no-label-form': isAdminConsoleNoLabelMode
        })}
        >
          <span className='badge'>
            <EnterpriseIcon className="icon" />
            {t(ALL_TEXTS.ADMIN_CONSOLE_CAPITALIZED)}
          </span>
          <p className='description'>
            {translationRenderer(ALL_TEXTS.ADMIN_CONSOLE_DARK_MODAL_DESCRIPTION)({
              renderer1: str => <strong>{str}</strong>,
              renderer2: str => <span className="new-line">{str}</span>
            })}
          </p>
          <Graph className="graph-preview" />
          {isEducationDiscountMode && <EducationDiscountRenderer />}
        </div>
        {/* aside */}
        <div className={cx('right-col', {
          'no-label-form': isAdminConsoleNoLabelMode
        })}
        >
          <PrefilledLeadFormAside
            user={user}
            formID={getLiveFormID(isAdminConsoleNoLabelMode, target)}
            devFormID={getDevFormID(isAdminConsoleNoLabelMode, target)}
            gaAttributes={gaAttributes}
            gaFormTrackingItems={gaFormTrackingItems}
            title={t(ALL_TEXTS.SHORT_LEAD_FORM_TITLE)}
            formUiModifier={ADMIN_CONSOLE_DARK_MODAL}
            onFormSubmit={handleFormSubmit}
            isAdForm={isAdForm}
          />
        </div>
      </div>
    </Modal>
  );
});

AdminConsoleDarkModal.propTypes = {
  user: userPropType.isRequired,
  modalProps: object,
  formID: string.isRequired,
  devFormID: string.isRequired,
  isEduTestVariant: bool,
  isAdminConsoleNoLabelMode: bool,
  onClose: func,
  onCloseClick: func,
  logAbTestAction: func,
  isEducationDiscountMode: bool,
  expandableAdminConsoleButtonMode: bool
};

AdminConsoleDarkModal.defaultProps = {
  modalProps: {},
  isEduTestVariant: false,
  isAdminConsoleNoLabelMode: false,
  onClose: f => f,
  onCloseClick: f => f,
  logAbTestAction: f => f,
  isEducationDiscountMode: false,
  expandableAdminConsoleButtonMode: false
};

export default AdminConsoleDarkModal;
