import React from 'react';
import { func } from 'prop-types';
import { IconCheckCircle } from '@jotforminc/svg-icons';
import { ALL_TEXTS } from '@jotforminc/enterprise-promotions-utils';

const ThankYouStep = ({ onClose }) => {
  return (
    <div className='w-full min-h-90vh px-4 flex items-center justify-center'>
      <div
        className='flex flex-col items-center bg-navy-10 w-full max-w-sm px-10 py-14 md:-mt-24'
        style={{ borderRadius: '16px' }}
      >
        <IconCheckCircle className="shrink-0 w-16 h-auto mb-8 fill-green-500" />
        <p className='color-navy-700 font-medium text-xl line-height-2xl mb-3 text-center'>{ALL_TEXTS.REQUEST_RECEIVED}</p>
        <p className='color-navy-300 text-md line-height-xl mb-10 text-center'>{ALL_TEXTS.THANKS_FOR_GETTING_TOUCH}</p>
        <button
          type="button"
          onClick={onClose}
          className="duration-300 radius p-3 min-w-60
          text-sm line-height-xs color-white font-medium
          bg-blue-500 hover:bg-blue-600 focus:bg-blue-500
          outline-0 focus:outline-4 outline-solid outline-blue-200 outline-opacity-50
          "
        >
          {ALL_TEXTS.GO_TO_PRICING}
        </button>
      </div>
    </div>
  );
};

ThankYouStep.propTypes = {
  onClose: func
};

ThankYouStep.defaultProps = {
  onClose: f => f
};

export default ThankYouStep;
